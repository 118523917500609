import { axiosInstance } from "./../config/config";

const authEndpoint = "/auth/";
const tokenKey = "token";

export async function login(email: string, password: string) {
  const { data: jwt } = await axiosInstance.post(authEndpoint, {
    email,
    password,
  });

  localStorage.setItem(tokenKey, jwt);
  return jwt;
}

export function loginWithJwt(jwt: string) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getJWT() {
  return localStorage.getItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt: string = localStorage.getItem(tokenKey)!;
    axiosInstance.defaults.headers.common["x-auth-token"] = jwt;
    const _user = parseJwt(jwt);
    console.log(_user, "currentUser");
    return _user;
  } catch (ex) {
    const _ = {};
    return undefined;
  }
}
function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
