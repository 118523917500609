import * as react from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  selectAccount,
  selectSignature,
  selectLottoMint1Tokens,
  selectG1Tokens,
  selectG2Tokens,
  selectG3Tokens,
  selectG4Tokens,
  setAccount,
  setSignature,
  setLottoMint1Tokens,
  setG1Tokens,
  setG2Tokens,
  setG3Tokens,
  setG4Tokens,
  setNonce,
  selectNonce,
} from "../store/mainSlice";
import Navbar from "./navbar";
import Content from "./content";
import Footer from "./common/Footer";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import { axiosInstance } from "../config/config";
import { connectWallet, signMessage } from "../tools/wallet";
import { getCurrentUser } from "./../services/auth";
import { User, TokenInfo } from "./../types";
import { getTokensOwned } from "./../services/queries";

let websocket = new WebSocket(process.env.REACT_APP_WSS_URL!);
export const themeOptions = {};

const theme = createTheme(themeOptions);

export const AccountContext = react.createContext<User | undefined>(undefined);

export default function Main() {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const nonce = useAppSelector(selectNonce);
  const [currentUser, setCurrentUser] = react.useState<User | undefined>(
    undefined
  );

  const [currentNetwork, setCurrentNetwork] = react.useState("");
  const [error, setError] = react.useState("");

  websocket.onopen = () => {
    console.log("connected");
  };
  websocket.onmessage = async (evt) => {
    if (evt.data) {
      let data = JSON.parse(evt.data);
      console.log("nonce received");
      if (data.nonce) {
        dispatch(setNonce(data.nonce));
        setTimeout(async () => {
          const signature = await signMessage(data.nonce);
          dispatch(setSignature(signature));
        }, 1000);
      }
    }
  };
  websocket.onerror = (evt) => {
    console.log(evt);
  };
  websocket.onclose = (evt) => {
    console.log(evt);
  };

  const checkIfWalletIsConnected = react.useCallback(async () => {
    /*
     * First make sure we have access to window.ethereum
     */
    const { ethereum } = window as any;

    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    } else {
      console.log("We have the ethereum object", ethereum);
    }

    await ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x89",
          chainName: "Polygon",
          rpcUrls: ["https://polygon-rpc.com/"],
        },
      ],
    });

    const accounts = await ethereum.request({ method: "eth_accounts" });

    /*
     * User can have multiple authorized accounts, we grab the first one if its there!
     */
    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);

      dispatch(setAccount(account));
      setCurrentNetwork("0x89");

      let chainId = await ethereum.request({ method: "eth_chainId" });
      console.log("Connected to chain " + chainId);
    } else {
      console.log("No authorized account found");
    }
  }, [account]);
  react.useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  const dispatchWrapper = (address: string) => {
    dispatch(setAccount(address));
  };

  const connectAndSign = async () => {
    let address = await connectWallet(
      currentNetwork,
      dispatchWrapper,
      setCurrentNetwork,
      setError
    );

    const signature = await signMessage(nonce);
    console.log("getting tokens");
    dispatch(setSignature(signature!));

    dispatch(setLottoMint1Tokens(await getTokensOwned(address, "LottoMint1")));
    dispatch(setG1Tokens(await getTokensOwned(address, "G1")));
    dispatch(setG2Tokens(await getTokensOwned(address, "G2")));
    dispatch(setG3Tokens(await getTokensOwned(address, "G3")));
    dispatch(setG4Tokens(await getTokensOwned(address, "G4")));
    //load user dogs after signature
  };

  const loadTokens = react.useCallback(async () => {
    if (account) {
      console.log("loading tokens");
      dispatch(
        setLottoMint1Tokens(await getTokensOwned(account, "LottoMint1"))
      );
      dispatch(setG1Tokens(await getTokensOwned(account, "G1")));
      dispatch(setG2Tokens(await getTokensOwned(account, "G2")));
      dispatch(setG3Tokens(await getTokensOwned(account, "G3")));
      dispatch(setG4Tokens(await getTokensOwned(account, "G4")));
    }
  }, [account]);

  react.useEffect(() => {
    loadTokens();
  }, [account]);

  react.useEffect(() => {
    const decodedJWT = getCurrentUser();

    setCurrentUser(decodedJWT);
  }, [getCurrentUser]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AccountContext.Provider value={currentUser}>
          <Navbar
            currentUsername={currentUser && currentUser.username}
            handleConnect={connectAndSign}
          ></Navbar>
          <Content address={account} networkId={currentNetwork}></Content>
          <Footer></Footer>
          <Snackbar
            open={!!error}
            onClose={() => setError("")}
            autoHideDuration={5000}
            message={error}
          ></Snackbar>
        </AccountContext.Provider>
      </ThemeProvider>
    </>
  );
}
