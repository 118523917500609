import { TokenInfo } from "../types";

export function getTimeToRace(raceStartTime: number) {
  //unix timestamp only
  let diff = raceStartTime - Date.now();

  let seconds = Math.floor(diff / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  let timeToRace = {
    days: days,
    hours: hours,
    minutes: minutes % 60,
    seconds: seconds % 60,
  };
  return timeToRace;
}
export const sortArrayByKey = (array: any[], key?: string) => {
  return key
    ? array.sort((a: any, b: any) => {
        return a[key] - b[key];
      })
    : array.sort((a: any, b: any) => {
        return a - b;
      });
};

export const getBloodlineCount = (array: TokenInfo[]) => {
  //array of tokens, filter tokens by bloodline
  let bloodlines = array.map((token) => {
    let bloodlineAttrIndex = token.metadata?.attributes.findIndex(
      (attr) =>
        attr.trait_type === "Bloodline" || attr.trait_type === "bloodline"
    );
    return token.metadata?.attributes[bloodlineAttrIndex!].value;
  });
  return new Set(bloodlines).size;
};

export const countUniqueProperty = (array: any[], key: string) => {
  let unique = array.map((item) => item[key]);
  console.log(array);
  console.log(unique);
  return new Set(unique).size;
};
