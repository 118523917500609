import * as react from "react";
import Button from "@mui/material/Button";
import moment from "moment";
import { useParams } from "react-router";
import { trimBloodlineAttribute } from "../kennel";
import { TokenInfo, TokenMetadata, Race } from "../../../types";
import {
  useJoinRace,
  useStartRace,
  useCurrentRace,
  getTokenMetadata,
  getTokenImage,
} from "../../../services/queries";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  selectAccount,
  selectSignature,
  setSignature,
  selectLottoMint1Tokens,
  selectNonce,
} from "../../../store/mainSlice";
import { signMessage } from "../../../tools/wallet";
import RaceMain from "../../race-main";
import RaceSummary from "./racesummary";
import Popup from "../../common/Popup";
import JoinPopup from "./joinpopup";
import SponsorPopup from "./sponsorpopup";
import { ClassIndicator } from "./racelist";
import Clipboard from "../../assets/clipboard.svg";
import PlayIcon from "../../assets/play.png";
import ExternalLink from "../../assets/external-link.png";
import PlaceholderDog from "../../assets/placeholderdog.png";
import { getTimeToRace } from "../../../tools/helper";
import DefaultSponsor from "../../assets/icons/default_sponsor.jpeg";

export default function RaceDetails() {
  const { uuid } = useParams(); //RaceID
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const nonce = useAppSelector(selectNonce);
  const signature = useAppSelector(selectSignature);
  const [progress, setProgress] = react.useState("idle");
  const [tokenData, setTokenData] = react.useState<TokenMetadata[] | undefined>(
    undefined
  );
  const [showSummary, setShowSummary] = react.useState(true);
  const [showJoinPopup, setShowJoinPopup] = react.useState(false);
  const LottoMint1Tokens = useAppSelector(selectLottoMint1Tokens);

  //race data
  const [raceData, setRaceData] = react.useState<Race | undefined>(undefined);

  const [distRan, setDistRan] = react.useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [currentTick, setCurrentTick] = react.useState(0);
  const [maxTick, setMaxTick] = react.useState(0);
  const [entrantPosition, setEntrantPos] = react.useState<
    number[] | undefined
  >();
  const [error, setError] = react.useState("");
  const [time, setTime] = react.useState<number>(0);

  const join = useJoinRace();
  const start = useStartRace();

  const { data, isLoading, isSuccess, isFetching, refetch } = useCurrentRace(
    uuid!
  );
  const [invisibleTimer, setInvisibleTimer] = react.useState<number>(0);
  const [raceFinished, setRaceFinished] = react.useState(false);

  react.useEffect(() => {
    if (data && isSuccess) setRaceData(data.data);
  }, [data, isSuccess]);
  //Following use effect is to start the race if user already in the page
  react.useEffect(() => {
    if (!raceData) return;
    if (progress === "idle" && raceData.startTime >= Date.now()) {
      let interval = setInterval(() => {
        setInvisibleTimer((prev) => {
          console.log(prev);
          if (prev === 10) {
            console.log("refetching");
            refetch();
          }
          return Math.floor((raceData.startTime - Date.now()) / 1000);
        });
      }, 1000);
      return () => clearInterval(interval);
    }
    if (
      raceData.raceTime &&
      raceData.startTime + raceData.raceTime * 1000 < Date.now()
    ) {
      console.log("race finished");
      setRaceFinished(true);
    } else {
      setRaceFinished(false);
    }
  }, [progress, raceData]);

  react.useEffect(() => {
    if (raceData && raceData.raceTime) {
      if (
        raceData.completed &&
        raceData.startTime < Date.now() &&
        raceData.startTime + raceData.raceTime * 1000 > Date.now()
      ) {
        console.log("----auto-start----");
        //TODO: fast-forward to the current tick
        watchRace();
      } else {
        console.log("dont auto start");
      }
    }
  }, [raceData, invisibleTimer]);

  const joinRace = async (tokenFamily: string, tokenId: number) => {
    await refetch();
    try {
      if (!signature) {
        let sig = await signMessage(nonce);
        dispatch(setSignature(sig));
        let res = await join.mutateAsync({
          raceId: uuid,
          ownerAddress: account,
          tokenId: tokenId,
          tokenFamily: tokenFamily,
          signature: sig,
        });
      } else {
        let res = await join.mutateAsync({
          raceId: uuid,
          ownerAddress: account,
          tokenId: tokenId,
          tokenFamily: tokenFamily,
          signature: signature,
        });
      }
      refetch();
    } catch (e: any) {
      setError(e.response.data);
    }
  };
  const watchRace = () => {
    resetRace();
    //Do start sequence
    Promise.resolve().then(() => {
      setProgress("countdown");
      setTimeout(() => {
        setProgress("racing");
      }, 5100);
    });
  };

  const resetRace = () => {
    setProgress("idle");
    setTime(0);
    setShowSummary(false);
    setCurrentTick(0);
    setDistRan([0, 0, 0, 0, 0, 0, 0, 0]);
    setEntrantPos(raceData?.joinOrder!);
    setFinishTimes([0, 0, 0, 0, 0, 0, 0, 0]);
    setFinished([false, false, false, false, false, false, false, false]);
  };

  const [finishTimes, setFinishTimes] = react.useState([
    0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [finished, setFinished] = react.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  react.useEffect(() => {
    setFinishTimes((prev) =>
      finished.map((f, i) => {
        if (f && prev[i] === 0) {
          return time;
        } else {
          return prev[i];
        }
      })
    );
    if (finished.filter((f) => f).length === 8) {
      setProgress("finished");
      let initDelay = setTimeout(() => {
        setShowSponsorPopup(true);
        setTimeout(() => {
          setShowSponsorPopup(false);
          setShowSummary(true);
        }, 4000);
      }, 1000);

      return () => {
        clearTimeout(initDelay);
      };
    }
  }, [finished]);

  const updateDistances = (tick: number) => {
    if (!raceData) return;
    setDistRan((prev) => {
      let distCalc: number[] = [];
      for (let i = 0; i < raceData.entrants.length; i++) {
        //modifier + 0.05 seconds since prev update interval

        let totalDist = raceData.raceDists[i][tick];

        if (prev[i] >= raceData.raceDistance) {
          setFinished((prev) => {
            let newFin = [...prev];
            newFin[i] = true;
            return newFin;
          });
          distCalc.push(
            raceData.raceDistance +
              20 -
              raceData.outcome!.findIndex((x: any) => x.joinPos === i) * 1.15
          );
        } else {
          distCalc.push(totalDist);
        }
      }

      return distCalc;
    });
  };

  react.useEffect(() => {
    if (raceData) {
      setMaxTick(raceData.raceSpeeds ? raceData.raceSpeeds[0].length - 1 : 0);
      if (!entrantPosition) {
        console.log("setting entrant positions");
        setEntrantPos(raceData.joinOrder);
      }
    }
  }, [raceData, isSuccess]);

  react.useEffect(() => {
    if (progress === "racing") {
      const interval = setInterval(() => {
        //server ticks are every 100ms so we need to update the time every 100ms
        //however we want to setInterval 10ms for smoothness
        updateDistances(currentTick);
      }, 50);
      return () => {
        clearInterval(interval);
      };
    }
  }, [currentTick, progress]);

  react.useEffect(() => {
    if (progress === "racing") {
      let startTime = Date.now();
      const interval = setInterval(() => {
        let delta = Date.now() - startTime;
        setTime((prevTime) => delta);
      }, 50);
      return () => {
        clearInterval(interval);
      };
    }
  }, [progress]);

  react.useEffect(() => {
    if (progress === "racing") {
      const interval = setInterval(() => {
        setCurrentTick((prev) => {
          return prev + 1 < maxTick ? prev + 1 : prev;
        }); // check if max tick data reached
      }, 100);
      return () => {
        clearInterval(interval);
      };
    }
  }, [progress]);

  var indices = new Array(8);
  react.useEffect(() => {
    if (progress === "racing") {
      var len = distRan.length;
      for (var i = 0; i < len; ++i) indices[i] = raceData!.entrants[i].joinPos;
      indices.sort(function (a, b) {
        return distRan[a] < distRan[b] ? 1 : distRan[a] > distRan[b] ? -1 : 0;
      });

      setEntrantPos(indices);
    }
  }, [distRan]);

  const startRace = async (raceId: string) => {
    setError("");
    try {
      let res = await start.mutateAsync({
        raceId: raceId,
      });
      console.log(res, "start race");

      //current.refetch();
      refetch();
    } catch (e: any) {
      console.log(e, "ERROR STARTING");
      setError(e.response.data);
    }
  };

  const updateTokenInfo = react.useCallback(async () => {
    if (!raceData) return;
    let toUpdate: any[] = Array(8).fill(0);
    if (raceData.entrants) {
      for (let i = 0; i < raceData.entrants.length; i++) {
        let boxNumber = raceData.entrants[i].joinPos;
        let tokenInfo = await getTokenMetadata(
          raceData.entrants[i].tokenId,
          raceData.entrants[i].tokenFamily
        );

        toUpdate[boxNumber] = tokenInfo;
      }
    }

    setTokenData(toUpdate);
  }, [raceData]);

  react.useEffect(() => {
    updateTokenInfo();
  }, [raceData]);

  react.useEffect(() => {
    if (!raceData) return;
    if (
      raceData.completed &&
      raceData.startTime &&
      Date.now() - moment(raceData.startTime).valueOf() >
        1000 * (raceData.raceTime || 0)
    ) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [raceData]);

  const closePopup = () => {
    setShowJoinPopup(false);
    let content = document.getElementById("root");
    content!.style.overflow = "";
  };

  const openPopup = async () => {
    setShowJoinPopup(true);

    let content = document.getElementById("root");
    content!.style.overflow = "hidden";
    await refetch();
  };

  const countdown = getTimeToRace(raceData?.startTime || 0);

  const [showSponsorPopup, setShowSponsorPopup] = react.useState(false);

  return (
    <>
      {showSponsorPopup ? (
        <>
          <Popup>
            <SponsorPopup
              sponsorLink={raceData!.sponsorLink || ""}
              sponsorMessage={
                raceData!.sponsorMessage ||
                "This race is proudly brought to you by..."
              }
              sponsorImage={raceData!.sponsorImage || DefaultSponsor}
            ></SponsorPopup>
          </Popup>
        </>
      ) : (
        <></>
      )}
      {showJoinPopup && raceData && (
        <Popup>
          <JoinPopup
            handleJoin={joinRace}
            handleClose={closePopup}
            raceData={raceData}
            joinStatus={join}
          ></JoinPopup>
        </Popup>
      )}

      <div className="race-detail">
        {isLoading ? (
          <>
            <div>Loading Race Data...</div>
          </>
        ) : (
          <></>
        )}

        {raceData && (
          <>
            <div className="titles grid-5">
              <div>Race Event</div>
              <div>Location</div>
              <div>Track Conditions</div>
              <div>Weather</div>
              {raceData.sponsorImage ? <div>Sponsor</div> : <div></div>}
            </div>
            <div className="info-bar">
              <div className="grid-5">
                <span className="trackname">
                  {raceData.raceName} :
                  {Date.now() < raceData.startTime
                    ? `${countdown.hours ? countdown.hours + "h" : ""} ${
                        countdown.minutes ? countdown.minutes + "m" : ""
                      } ${countdown.seconds ? countdown.seconds + "s" : ""}`
                    : Date.now() <
                      (raceData?.raceTime || 0) * 1000 +
                        raceData.startTime +
                        5 * 1000 //countdown
                    ? raceData.cancelled
                      ? " Abandoned"
                      : " Live"
                    : raceData.cancelled
                    ? " Abandoned"
                    : raceData.raceTime
                    ? " Finished"
                    : " "}
                </span>
                <div className="planet">
                  <span>{raceData.raceLocation || "BetNFT"} </span>
                  <img src={Clipboard} className="clipboard"></img>
                </div>
                <div className="track-conditions">{raceData.raceCondition}</div>
                <div className="track-conditions">{raceData.raceWeather}</div>
                {raceData.sponsorImage ? (
                  <div className="sponsor">
                    <div className="msg">
                      {raceData.sponsorMessage ||
                        "This race is proudly brought to you by..."}
                    </div>
                    <a href={raceData.sponsorLink || ""} target="_blank">
                      <img
                        src={raceData.sponsorImage}
                        alt="sponsor"
                        className=""
                      />
                    </a>
                  </div>
                ) : (
                  //Default BETNFT logo
                  <div className="sponsor">
                    <div className="msg">
                      This race is proudly brought to you by...
                    </div>
                    <a>
                      <img src={DefaultSponsor} alt="sponsor" className="" />
                    </a>
                  </div>
                )}
              </div>
              <div className="wrapper">
                {/* <div className="watch-3d">
                  Watch 3D <img src={ExternalLink}></img>
                </div>
                <div className="live">
                  <img src={PlayIcon}></img>Live
                </div> */}
              </div>
            </div>
            <div className="extra-info-bar">
              <div className="info event">
                Event Type <ClassIndicator>{raceData.raceClass}</ClassIndicator>
              </div>
              <div className="info">
                Distance <span>{raceData.raceDistance}m</span>
              </div>
              <div className="info">
                Status
                <span>
                  {raceData.cancelled
                    ? "Abandoned"
                    : raceFinished
                    ? "Completed"
                    : "Live"}
                </span>
              </div>
              <div className="info">
                Time Elapsed <span>{formatTimer(time)}</span>
              </div>
              <div className="info">
                Entry Fee
                <span>
                  {!raceData.entryFee ? "Free" : `$${raceData.entryFee} USDT`}
                </span>
              </div>
              <div className="info">
                Prize Pool
                <span>
                  {raceData.prizePool ? `$${raceData.prizePool} ` : "$0 "}USDT
                </span>
              </div>
              <div className="info">
                Registered
                <span>
                  {raceData.entrants
                    ? `${raceData.entrants.length}/${8}`
                    : "0/8"}
                </span>
              </div>

              {!raceFinished &&
                raceData.entrants.length < 8 &&
                !raceData.cancelled && (
                  <>
                    {/* <Button onClick={() => startRace(data?.data.raceId)}>
                  Start
                </Button> */}
                    <Button
                      variant="contained"
                      onClick={() => {
                        openPopup();
                      }}
                    >
                      Join Race
                    </Button>
                  </>
                )}
              {raceFinished && !raceData.cancelled && (
                <>
                  <Button onClick={watchRace}>Watch Replay</Button>
                  {/* <Button onClick={resetRace}>// Reset Track // </Button> */}
                </>
              )}
            </div>
          </>
        )}

        <div className="blackbar"></div>
        {/* TODO: Dont hide unless after race is completed*/}
        {raceData ? (
          <>
            {tokenData && showSummary && (
              <RaceSummary
                raceData={raceData}
                fromLiveRace={true}
              ></RaceSummary>
            )}
            {!showSummary && tokenData && (
              <div className="race-area">
                <div className="sections">
                  <div className="positions">
                    <div className="icon first">1st</div>
                    <div className="icon second">2nd</div>
                    <div className="icon third">3rd</div>
                    <div className="icon">4th</div>
                    <div className="icon">5th</div>
                    <div className="icon">6th</div>
                    <div className="icon">7th</div>
                    <div className="icon">8th</div>
                  </div>
                  <div className="racers">
                    {tokenData.map((token, index) => {
                      if (!token) return;
                      let bloodlineIndex = token.attributes.findIndex(
                        (e) => e.trait_type === "Bloodline"
                      );
                      let [bloodlineTag, bloodlineName] =
                        trimBloodlineAttribute(
                          token.attributes[bloodlineIndex!].value
                        );

                      return (
                        <div
                          key={index.toString() + token.edition}
                          className="row"
                          style={{
                            top: `calc(${
                              (progress === "racing" ||
                                progress === "finished") &&
                              entrantPosition
                                ? entrantPosition.indexOf(index) * 12.5
                                : index * 12.5
                            }% + 5px)`,
                          }}
                        >
                          <div className="dog">
                            <div className="img-container">
                              <img
                                src={
                                  token.image
                                    ? getTokenImage(token.image)
                                    : PlaceholderDog
                                }
                              ></img>
                            </div>

                            {/* <img src={DividerTest} className="divider"></img> */}
                            <div className="name-type">
                              <div className="name">
                                {token.name}
                                {finished[index] === true &&
                                  " - " + formatTimer(finishTimes[index])}
                              </div>
                              <div className="type">
                                {bloodlineTag + " - " + bloodlineName}
                              </div>
                            </div>
                            <div className="number">#{index + 1}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {data && (
                    <div className="racetrack">
                      <Countdown progress={progress}></Countdown>
                      <RaceMain
                        race={raceData}
                        progress={progress}
                        distances={distRan}
                      ></RaceMain>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="loading">Loading...</div>
        )}
      </div>
    </>
  );
}

interface CountdownProps extends React.HTMLAttributes<HTMLDivElement> {
  progress: string;
}

function Countdown(props: CountdownProps) {
  const [count, setCount] = react.useState(5);
  react.useEffect(() => {
    if (props.progress === "countdown") {
      let countdown = setInterval(() => {
        setCount((prev) => {
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(countdown);
    } else {
      setTimeout(() => {
        setCount(5);
      }, 1000);
    }
  }, [props.progress]);
  return (
    <>
      <div
        className="countdown"
        style={{
          visibility: props.progress === "countdown" ? "visible" : "hidden",
          opacity: props.progress === "countdown" ? "1" : "0",
          color: count > 0 ? "" : "yellow",
        }}
      >
        {count > 0 ? count : "Go!"}
      </div>
    </>
  );
}

const formatTimer = (time: number) => {
  let seconds = (time % 60000) / 1000;

  var minutes = Math.floor(time / 60000);
  let _min = minutes > 0 ? `${minutes}m` : "";
  let formatted = `${_min} ${seconds.toFixed(2)}s`;
  return formatted;
};
