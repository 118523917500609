import "./style.scss";
import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../../../../config/config";
import * as react from "react";
import { useParams } from "react-router";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectLottoMint1Tokens,
  selectG1Tokens,
  selectG2Tokens,
  selectG3Tokens,
  selectG4Tokens,
  selectSignature,
  selectAccount,
} from "../../../../store/mainSlice";
import {
  useTournament,
  useJoinTournament,
  useCurrentTournament,
} from "../../../../services/queries";

export default function Tournament() {
  const { uuid } = useParams(); //RaceID
  console.log(uuid);

  const tournament = useCurrentTournament(uuid!);
  const { data: info } = tournament.data
    ? tournament.data
    : { data: undefined };

  const final = useQuery(
    ["final-" + info?.races[info?.races.length - 1]],
    () => {
      return axiosInstance.get(
        `/races/${info?.races[info?.races.length - 1]}`,
        {
          params: {
            raceId: info?.races[info?.races.length - 1],
          },
        }
      );
    },
    { enabled: !!tournament.data?.data }
  );

  const joinTournament = useJoinTournament();

  const LottoMint1Tokens = useAppSelector(selectLottoMint1Tokens);
  const G1Tokens = useAppSelector(selectG1Tokens);
  const G2Tokens = useAppSelector(selectG2Tokens);
  const G3Tokens = useAppSelector(selectG3Tokens);
  const G4Tokens = useAppSelector(selectG4Tokens);
  const signature = useAppSelector(selectSignature);
  const account = useAppSelector(selectAccount);

  const [allTokens, setAllTokens] = react.useState<any[]>([]);
  react.useEffect(() => {
    setAllTokens([
      ...G1Tokens,
      ...G2Tokens,
      ...G3Tokens,
      ...G4Tokens,
      ...LottoMint1Tokens,
    ]);
  }, [LottoMint1Tokens, G1Tokens, G2Tokens, G3Tokens, G4Tokens]);

  const handleJoin = async (
    tokenId: string,
    tokenFamily: string,

    tournamentId: string
  ) => {
    if (!signature) {
      alert("Please sign in first");
      return;
    }
    if (!account) {
      alert("Please connect wallet first");
      return;
    }
    try {
      let success = await joinTournament.mutateAsync({
        tokenId: tokenId,
        tokenFamily: tokenFamily,
        ownerAddress: account,
        tournamentId: tournamentId,
        signature: signature,
      });
      console.log("Success join tournament!", success);
      tournament.refetch();
    } catch (e) {
      console.log("Join tournament error", e);
    }
  };

  react.useEffect(() => {
    if (!tournament.data) return;
    if (tournament.data.data.raceId) {
      final.refetch();
    }
  }, [tournament.data]);

  return (
    <>
      <div className="tournament">
        {tournament.isLoading ? (
          "Loading information..."
        ) : (
          <>
            <div style={{ display: "flex" }}>
              {info ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Class</th>
                        <th>Fee</th>
                        <th>Prize</th>
                        <th>Start</th>
                        <th>Status</th>
                        <th>Races</th>
                        <th>Entrants</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        <tr key={info.id}>
                          <td>{info.id}</td>
                          <td>{info.name}</td>
                          <td>{info.location}</td>
                          <td>{info.t_class}</td>
                          <td>{info.entryFee}</td>
                          <td>{info.prizePool}</td>
                          <td>{new Date(info.cutoffTime).toLocaleString()}</td>
                          <td>{info.status}</td>
                          <td className="t-races">
                            {info.races.map((r: any) => {
                              return <div>{r}</div>;
                            })}
                          </td>
                          <td className="t-entrants">
                            {info.entrants.map((token: any, index: number) => {
                              return (
                                <div key={"e" + index}>
                                  {index + 1}. {token.tokenId} -{" "}
                                  {token.tokenFamily}, Points:{" "}
                                  {token.points || 0}
                                </div>
                              );
                            })}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                  <div>
                    Final Race - {final.data ? final.data.data.raceId : ""}
                    {final.data
                      ? final.data.data.outcome.map((a: any, index: number) => {
                          return (
                            <div>
                              Pos {index + 1}. {a.tokenId} - {a.tokenFamily}
                            </div>
                          );
                        })
                      : "No Final race found"}
                  </div>
                  {info.status === "waiting" || info.status === "pending" ? (
                    <div className="tourn-tokens">
                      Your Tokens
                      {allTokens.map((token) => [
                        <div
                          className="token"
                          key={token.id + token.tokenFamily}
                        >
                          {token.tokenId} - {token.tokenFamily}{" "}
                          <button
                            onClick={() =>
                              handleJoin(
                                token.tokenId,
                                token.tokenFamily,
                                info.id
                              )
                            }
                          >
                            Join
                          </button>
                        </div>,
                      ])}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>No tournament found</>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
