import * as react from "react";

interface IProps {
  handleSubmit: (e: react.SyntheticEvent) => void;
  handleChange: (e: react.ChangeEvent<HTMLInputElement>) => void;
}

export default function Register(props: IProps) {
  const { handleSubmit, handleChange } = props;
  //create react state to hold form values for user details first name, last name, email, password

  return (
    <>
      <div className="register">
        <h2>Register an account </h2>
        <form
          id="register-form"
          onSubmit={handleSubmit}
          className="register-form"
        >
          <div className="form-row">
            <label htmlFor="username">Username:</label>
            <input
              name="username"
              placeholder=""
              onChange={handleChange}
            ></input>
          </div>
          <div className="form-row">
            <label htmlFor="email">Email:</label>
            <input
              name="email"
              type="email"
              placeholder=""
              onChange={handleChange}
            ></input>
          </div>
          <div className="form-row">
            <label htmlFor="password">Password:</label>
            <input
              name="password"
              type="password"
              placeholder=""
              onChange={handleChange}
            ></input>
          </div>
          <button>Register</button>
        </form>
      </div>
    </>
  );
}
