import * as react from "react";
import "./kennelsettings.scss";
import { TokenInfo } from "../../../types";
import { getTokenImage } from "../../../services/queries";
import LogoWShadow from "../../assets/logowshadow.png";
import PlaceholderKennel from "../../assets/placeholder_kennel.png";
import { UseMutationResult } from "@tanstack/react-query";
interface IProps {
  handleSubmitName: (kennelName: string, email: string) => void;
  handleClose: () => void;
  children?: react.ReactNode;
  error?: string;
  mutation: UseMutationResult<any>;
}

export default function KennelSettings(props: IProps) {
  const [inputValue, setInputValue] = react.useState("");
  const [email, setEmail] = react.useState("");
  react.useEffect(() => {
    if (props.mutation.data?.data.name) {
      setInputValue(props.mutation.data?.data.name);
    }
  }, [props.mutation.data]);

  return (
    <>
      <div className="settings-bg">
        <div className="container">
          <div className="title">Kennel Setup</div>

          <div className="input-wrapper">
            <label htmlFor="dog-name" className="input-label">
              Kennel Name *
            </label>
            <input
              disabled={props.mutation.data?.data.edited}
              placeholder={
                props.mutation.data?.data.name || "Enter a Kennel Name"
              }
              value={inputValue}
              type="text"
              name="dog-name"
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className="conditional">
            * Inappropriate names will not be accepted
          </div>
          <div className="input-wrapper">
            <label htmlFor="email" className="input-label">
              EMAIL
            </label>
            <input
              placeholder={
                props.mutation.data?.data.email || "Enter your email"
              }
              value={email}
              id="email"
              type="email"
              name="dog-name"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="button-row">
            <button
              className="save"
              onClick={() => props.handleSubmitName(inputValue, email)}
              disabled={props.mutation.isLoading}
            >
              Save
            </button>
            <button
              className="discard"
              onClick={() => props.handleClose()}
              disabled={props.mutation.isLoading}
            >
              Discard
            </button>
          </div>

          <div className="error">{props.error} </div>
        </div>
        <div className="kennel-image">
          <span>Kennel Image</span>
          <img src={PlaceholderKennel} alt="kennelimg"></img>
        </div>

        <img src={LogoWShadow} alt="logo" className="logo" />
      </div>
    </>
  );
}
