import * as react from "react";
import { Race, TokenInfo } from "../../types";
import Dog from "./dog";
import TrackArrows from "../../components/assets/track-arrows.png";
import "./style.scss";

interface IProps {
  race: Race;
  progress: string;

  distances: number[];
  finishing?: boolean;
}

let startPosOffset = 3;
let maxVisualDist = 47;
const initPos = startPosOffset / 100;
const initPositions = [
  initPos,
  initPos,
  initPos,
  initPos,
  initPos,
  initPos,
  initPos,
  initPos,
];
export default function RaceMain({ race, progress, distances }: IProps) {
  const [currentLegPos, setCurrentLegPos] = react.useState(100);
  const [currentLegPos2, setCurrentLegPos2] = react.useState(50);
  const [positions, setPositions] = react.useState(initPositions);
  const [bodyOffset, setBodyOffset] = react.useState(0);
  const [currentLeg, setCurrentLeg] = react.useState(0);
  const [trackMax, setTrackMax] = react.useState(0); // should be around 50-70, currently everything hardcoded to 50

  const updatePositions = (dists: number[]) => {
    setPositions((prev) => {
      //start
      let leadRunner = Math.max(...dists);

      if (leadRunner < maxVisualDist) {
        setBodyOffset((leadRunner * 90) / maxVisualDist);
        let updated = prev.map((position, i) => {
          return (
            ((dists[i] / 100) * (maxVisualDist - startPosOffset)) /
              maxVisualDist +
            startPosOffset / 100
          );
        });
        return updated;
      }
      //main
      //setBodyOffset(3); //40px

      if (leadRunner > maxVisualDist && leadRunner < race.raceDistance - 25) {
        //set the positions of each dog to be the distance it has travelled as a percentage of the race leader
        // this is one implementation to use % of race leader

        let updated = prev.map((position, i) => {
          let gap = leadRunner - dists[i];
          return (maxVisualDist - gap) / 100;
        });
        return updated;

        //TODO: another implementation would be to recieve positions from back end and lerp between them directly focus on leader of the race
      }
      //finish
      let updated = prev.map((position, i) => {
        return (dists[i] - race.raceDistance + 75) / 100;
      });

      return updated;
    });
  };

  react.useEffect(() => {
    if (progress === "racing") {
      let interval1 = setInterval(() => {
        updatePositions(distances);
      }, 10);
      return () => clearInterval(interval1);
    }

    if (progress === "idle") {
      setPositions(initPositions);
      setBodyOffset(0);
    }
  }, [progress, distances]);

  const [incrementLeg, setIncrementLeg] = react.useState(false);
  //update sets if we should put the indicator back to the right side of track
  react.useEffect(() => {
    if (progress === "racing") {
      if (incrementLeg) {
        setCurrentLeg((prev) => prev + 1);
        setIncrementLeg(false);
      }
    }
  }, [progress, incrementLeg]);

  //move the dist indicator
  react.useEffect(() => {
    if (progress === "racing") {
      let interval = setInterval(() => {
        let leader = Math.max(...distances);
        let gap = currentLeg * 100 + 100 - leader;

        let indicatorPos = gap >= 0 ? gap + maxVisualDist : maxVisualDist + gap;

        setCurrentLegPos((prev) => {
          if (leader > race.raceDistance - 25) {
            return 75;
          }
          if (leader <= maxVisualDist) {
            return prev;
          }

          //start moving the indicators when the fastest dog reaches 50m

          if (prev > 0 && leader > maxVisualDist) {
            setStartingLine((prevStart) => maxVisualDist - leader - 3); // 3 is starting line offset
            return indicatorPos;
          } else {
            setIncrementLeg(true);

            return 100;
          }
        });
        // setCurrentLegPos2((prev) => {
        //   if (leader > race.raceDistance - 25) {
        //     return 25;
        //   }
        //   if (leader <= maxVisualDist) return prev;

        //   if (prev > 0 && leader > 50) {
        //     return indicatorPos - maxVisualDist < 0
        //       ? indicatorPos + maxVisualDist
        //       : indicatorPos - maxVisualDist;
        //   } else {
        //     return 100;
        //   }
        // });
      }, 50);
      return () => clearInterval(interval);
    } else if (progress === "idle") {
      setCurrentLegPos(100);

      setStartingLine(-3);
      setCurrentLeg(0);
    }
  }, [progress, currentLeg, distances]);

  const [startingLine, setStartingLine] = react.useState(-3);

  return (
    <>
      <div
        className="startline"
        style={{
          transition: "all 300ms",
          left: `${startingLine}%`,
          display: `${startingLine > -25 ? "" : "none"}`,
        }}
      ></div>
      {
        <DottedLine
          key={currentLeg}
          pctPos={currentLegPos}
          rewindingEffect={currentLegPos < 0 ? false : true}
          value={`${race.raceDistance - 100 - currentLeg * 100}m`}
          finish={currentLeg * 100 + 100 === race.raceDistance}
        ></DottedLine>
      }

      {/* {
        <DottedLine
          key={currentLeg - 50}
          pctPos={
            currentLegPos - maxVisualDist < 0
              ? currentLegPos + maxVisualDist
              : currentLegPos - maxVisualDist
          }
          rewindingEffect={
            currentLegPos2 < 0 || currentLegPos2 > 98 ? false : true
          }
          value={""}
          finish={false}
        ></DottedLine>
      } */}

      <div className="lanes">
        <Arrows
          key={currentLeg}
          pctPos={currentLegPos - 100 - 100 * currentLeg}
          rewindingEffect={true}
          width={race.raceDistance}
        ></Arrows>

        <Dog
          color="red"
          number={1}
          style={{
            transition: "all 300ms",
            left: `calc(${positions[0] * 100}% - ${bodyOffset}px)`,
          }}
        ></Dog>
        {/* <img src={PlaceholderRaceDog}></img> */}
      </div>
      <div className="lanes">
        <Dog
          color="stripe"
          number={2}
          style={{
            transition: "all 300ms",
            left: `calc(${positions[1] * 100}% - ${bodyOffset}px)`,
          }}
        ></Dog>
      </div>
      <div className="lanes">
        <Arrows
          key={currentLeg}
          pctPos={currentLegPos - 100 - 100 * currentLeg}
          rewindingEffect={true}
          width={race.raceDistance}
        ></Arrows>

        <Dog
          color="white"
          number={3}
          style={{
            transition: "all 300ms",
            left: `calc(${positions[2] * 100}% - ${bodyOffset}px)`,
          }}
        ></Dog>
      </div>
      <div className="lanes">
        <Dog
          color="blue"
          number={4}
          style={{
            transition: "all 300ms",
            left: `calc(${positions[3] * 100}% - ${bodyOffset}px)`,
          }}
        ></Dog>
      </div>
      <div className="lanes">
        <Arrows
          key={currentLeg}
          pctPos={currentLegPos - 100 - 100 * currentLeg}
          rewindingEffect={true}
          width={race.raceDistance}
        ></Arrows>

        <Dog
          color="yellow"
          number={5}
          style={{
            transition: "all 300ms",
            left: `calc(${positions[4] * 100}% - ${bodyOffset}px)`,
          }}
        ></Dog>
      </div>
      <div className="lanes">
        <Dog
          color="green"
          number={6}
          style={{
            transition: "all 300ms",
            left: `calc(${positions[5] * 100}% - ${bodyOffset}px)`,
          }}
        ></Dog>
      </div>
      <div className="lanes">
        <Arrows
          key={currentLeg}
          pctPos={currentLegPos - 100 - 100 * currentLeg}
          rewindingEffect={true}
          width={race.raceDistance}
        ></Arrows>

        <Dog
          color="black"
          number={7}
          style={{
            transition: "all 300ms",
            left: `calc(${positions[6] * 100}% - ${bodyOffset}px)`,
          }}
        ></Dog>
      </div>
      <div className="lanes">
        <Dog
          color="pink"
          number={8}
          style={{
            transition: "all 300ms",
            left: `calc(${positions[7] * 100}% - ${bodyOffset}px)`,
          }}
        ></Dog>
      </div>
    </>
  );
}
//TODO: ZED USES BG IMAGE FOR THE TRACK
interface DottedLineProps {
  pctPos: number;
  rewindingEffect: boolean;
  value: number | string;
  finish: boolean;
}

function DottedLine(props: DottedLineProps) {
  const { pctPos, rewindingEffect, value, finish } = props;
  return (
    <>
      <div
        className="dotted"
        style={{
          left: `${pctPos}%`,
          transition: `${rewindingEffect ? "all 300ms" : ""}`,
        }}
      >
        {finish ? <div className="finishline"></div> : <span>{value}</span>}
      </div>
    </>
  );
}

//TODO: ZED USES BG IMAGE FOR THE TRACK
interface ArrowProps {
  pctPos: number;
  rewindingEffect: boolean;
  width: number; //length of the race
}

function Arrows(props: ArrowProps) {
  const { pctPos, rewindingEffect, width } = props;
  return (
    <>
      <div
        className="arrows"
        style={{
          left: `calc(${pctPos}% + 178px)`,
          transition: `${rewindingEffect ? "all 300ms" : ""}`,
          width: `calc(${width}% - 200px)`,
        }}
      ></div>
    </>
  );
}
