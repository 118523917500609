import * as react from "react";
import Button from "@mui/material/Button";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import UpdateHome from "./ContentUpdator";
import {
  useAllRaces,
  useCreateRace,
  useUpdateHomeContent,
} from "../../../services/queries";

const race_classes: string[] = ["G1", "G2", "G3", "G4", "Open", "Chance"];
const race_distances: number[] = [400, 500, 600, 700];
const weather_options: string[] = ["dry", "wet"];
const track_conditions: string[] = ["fast", "good"];

type MaxEntrantOptions = 2 | 3 | 4 | 5 | 6 | 7 | 8;

export default function CreateRace() {
  const updateHomeContent = useUpdateHomeContent();
  const [raceDateTime, setDateTime] = react.useState("");
  const [raceName, setRaceName] = react.useState("");
  const [raceLocation, setRaceLocation] = react.useState("");
  const [raceClassidx, setRaceClass] = react.useState<number | undefined>(
    undefined
  );
  const [raceDistanceidx, setRaceDistance] = react.useState<number | undefined>(
    undefined
  );
  const [weatherIdx, setWeather] = react.useState<number | undefined>(
    undefined
  );
  const [maxEntrants, setMaxEntrants] = react.useState<MaxEntrantOptions>(8);
  const [conditionsIdx, setConditions] = react.useState<number | undefined>(
    undefined
  );
  const [entryFee, setEntryFee] = react.useState<number | undefined>(undefined);
  const [prizePool, setPrizePool] = react.useState<number | undefined>(
    undefined
  );
  const [sponsorImage, setSponsorImage] = react.useState("");
  const [sponsorMsg, setSponsorMsg] = react.useState("");
  const [sponsorLink, setSponsorLink] = react.useState("");
  const [statusMsg, setStatusMsg] = react.useState("");
  const createRace = useCreateRace();
  const handleSubmit = async () => {
    setStatusMsg("");
    if (!raceDateTime) {
      setStatusMsg("Please select a date and time");
      return;
    }
    if (!raceName) {
      setStatusMsg("Please enter a name for the event");
      return;
    }
    if (!raceLocation) {
      setStatusMsg("Please enter a location for the event");
      return;
    }

    if (raceClassidx === undefined) {
      setStatusMsg("Please select a class for the event");
      return;
    }
    if (raceDistanceidx === undefined) {
      setStatusMsg("Please select a distance for the event");
      return;
    }
    if (weatherIdx === undefined) {
      setStatusMsg("Please select a weather condition for the event");
      return;
    }
    if (conditionsIdx === undefined) {
      setStatusMsg("Please select a track condition for the event");
      return;
    }
    //convert date to UTC
    const utcDate = moment(raceDateTime).utc(false).unix();

    try {
      let result = await createRace.mutateAsync({
        raceName: raceName,
        startTime: utcDate * 1000, //to millisecond format
        raceClass: race_classes[raceClassidx],
        raceDistance: race_distances[raceDistanceidx],
        raceLocation: raceLocation,
        maxEntrants: maxEntrants,
        entryFee: entryFee,
        prizePool: prizePool,
        raceWeather: weather_options[weatherIdx],
        raceCondition: track_conditions[conditionsIdx],
        sponsorImage: sponsorImage,
        sponsorMessage: sponsorMsg,
        sponsorLink: sponsorLink,
      });
      console.log(result.data);
      setStatusMsg(result.data || "Event created");
    } catch (e: any) {
      console.log(e, "ERROR CREATING RACE");
      setStatusMsg(e.response.data);
    }
  };
  const handleSelectClass = (index: number) => {
    setRaceClass(index);
  };
  const handleSelectDist = (index: number) => {
    setRaceDistance(index);
  };
  const handleDateTimeChange = (dateTime?: string | null) => {
    if (dateTime) {
      setDateTime(dateTime);
    }
  };

  return (
    <>
      <div
        style={{
          margin: "0.5rem",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="race_name">Name</label>
          <input
            type="text"
            name="race_name"
            onChange={(e) => setRaceName(e.target.value)}
          ></input>
          <label htmlFor="race_location">Location</label>
          <input
            type="text"
            name="race_location"
            onChange={(e) => setRaceLocation(e.target.value)}
          ></input>
          <label htmlFor="sponsor_img">Sponsor Image Link</label>
          <input
            type="url"
            name="sponsor_img"
            placeholder="https://via.placeholder.com/150"
            onChange={(e) => setSponsorImage(e.target.value)}
          ></input>
          <label htmlFor="sponsor_msg">Sponsor Message</label>
          <input
            type="text"
            name="sponsor_msg"
            onChange={(e) => setSponsorMsg(e.target.value)}
          ></input>{" "}
          <label htmlFor="sponsor_link">Sponsor External Link</label>
          <input
            type="url"
            placeholder="https://example.com"
            name="sponsor_link"
            onChange={(e) => setSponsorLink(e.target.value)}
          ></input>
          <label htmlFor="sponsor_link">Sponsor External Link</label>
          <input
            type="url"
            placeholder="https://example.com"
            name="sponsor_link"
            onChange={(e) => setSponsorLink(e.target.value)}
          ></input>
          <label htmlFor="sponsor_link">Max entrants (2-8)</label>
          <input
            type="number"
            placeholder="Enter a number 2-8"
            name="maxentrants"
            onChange={(e) =>
              setMaxEntrants(parseInt(e.target.value) as MaxEntrantOptions)
            }
          ></input>
          <label htmlFor="entry_fee">Entry Fee (USDT)</label>
          <input
            type="number"
            name="entry_fee"
            pattern="/d+"
            onChange={(e) => setEntryFee(parseInt(e.target.value))}
          ></input>
          <label htmlFor="prize_pool">Prize Pool (USDT)</label>
          <input
            type="number"
            name="prize_pool"
            pattern="/d+"
            onChange={(e) => setPrizePool(parseInt(e.target.value))}
          ></input>
          <label htmlFor="weather_options">Weather: </label>
          <div>
            {weather_options.map((option, index) => (
              <Button
                sx={weatherIdx === index ? { background: "green" } : {}}
                variant="contained"
                key={option}
                onClick={() => setWeather(index)}
              >
                {option}
              </Button>
            ))}
          </div>
          <label htmlFor="track_conditions">Track Conditions: </label>
          <div>
            {track_conditions.map((option, index) => (
              <Button
                sx={conditionsIdx === index ? { background: "green" } : {}}
                variant="contained"
                key={option}
                onClick={() => setConditions(index)}
              >
                {option}
              </Button>
            ))}
          </div>
          <label htmlFor="race_class">Class: </label>
          <div>
            {race_classes.map((race_class, index) => {
              return (
                <Button
                  sx={raceClassidx === index ? { background: "green" } : {}}
                  variant="contained"
                  key={race_class}
                  onClick={() => handleSelectClass(index)}
                >
                  {race_class}
                </Button>
              );
            })}
          </div>
          <label htmlFor="race_dist">Distance</label>
          <div>
            {race_distances.map((race_dist, index) => {
              return (
                <Button
                  sx={raceDistanceidx === index ? { background: "green" } : {}}
                  variant="contained"
                  key={race_dist}
                  onClick={() => handleSelectDist(index)}
                >
                  {race_dist}
                </Button>
              );
            })}
          </div>
          <label htmlFor="race_time">Starting Time</label>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={raceDateTime}
            onChange={(newValue) => {
              handleDateTimeChange(newValue);
            }}
          />
        </form>
        <Button
          variant={"contained"}
          onClick={handleSubmit}
          style={{ margin: "0 auto", maxWidth: "200px" }}
        >
          Create Race Event
        </Button>
        <div>{statusMsg}</div>
      </div>
      <UpdateHome></UpdateHome>
    </>
  );
}
