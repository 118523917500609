import * as react from "react";
import { getCurrentUser, logout } from "./../../../services/auth";
import { User } from "../../../types";
interface IProps {
  handleLogin: (e: react.SyntheticEvent) => void;
  handleChange: (e: react.ChangeEvent<HTMLInputElement>) => void;
}

export default function Login(props: IProps) {
  const { handleLogin, handleChange } = props;
  const [currentUser, setCurrentUser] = react.useState<User | undefined>(
    undefined
  );
  react.useEffect(() => {
    const user = getCurrentUser();
    setCurrentUser(user);
  }, []);
  return (
    <>
      {currentUser ? (
        <div>
          <h2>Current User</h2>
          <div>Username: {currentUser.username}</div>
          <div>Email: {currentUser.email}</div>
          <button
            onClick={() => {
              logout();
              window.location.href = "/";
            }}
          >
            Logout
          </button>
        </div>
      ) : (
        <div className="register">
          <h2>Log in to existing account</h2>
          <form className="register-form" onSubmit={handleLogin}>
            <div className="form-row">
              <label htmlFor="username">Username:</label>
              <input
                name="username"
                type="username"
                placeholder=""
                onChange={handleChange}
              ></input>
            </div>
            <div className="form-row">
              <label htmlFor="password">Password:</label>
              <input
                name="password"
                type="password"
                placeholder=""
                onChange={handleChange}
              ></input>
            </div>
            <button>Log In </button>
          </form>
        </div>
      )}
    </>
  );
}
