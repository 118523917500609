import * as react from "react";
import { useRegisterUser, useLogin } from "../../services/queries";
import Register from "./register";
import Login from "./login";
import { loginWithJwt } from "../../services/auth";
import "./style.css";
import { axiosInstance } from "../../config/config";
interface IProps {}

export default function Account(props: IProps) {
  //create react state to hold form values for user details first name, last name, email, password
  const [username, setUsername] = react.useState("");
  const [email, setEmail] = react.useState("");
  const [password, setPassword] = react.useState("");
  const [error, setError] = react.useState("");
  const [success, setSuccess] = react.useState("");

  //create react state to hold form values for username, password for logging in
  const [loginUsername, setLoginUsername] = react.useState("");
  const [loginPassword, setLoginPassword] = react.useState("");

  //create function to handle onChange event for input fields
  const handleRegisterChange = (event: react.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  const register = useRegisterUser();
  const login = useLogin();

  const handleLoginChange = (event: react.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    if (name === "username") {
      setLoginUsername(value);
    } else if (name === "password") {
      setLoginPassword(value);
    }
  };

  const handleSubmit = async (e: react.SyntheticEvent) => {
    setSuccess("");
    setError("");
    e.preventDefault();
    console.log("submit register form");
    try {
      let res = await register.mutateAsync({
        username: username,
        email: email,
        password: password,
      });

      console.log(res, "register result");
      registerSuccess();
    } catch (e: any) {
      setError(e.response.data);
      console.log(e);
    }
  };

  const handleLogin = async (e: react.SyntheticEvent) => {
    setSuccess("");
    setError("");
    e.preventDefault();
    try {
      let res = await login.mutateAsync({
        username: loginUsername,
        password: loginPassword,
      });

      const { data } = res; // JWT result

      loginWithJwt(data);
      window.location.href = "/";
    } catch (e: any) {
      setError(e.response.data);
      console.log(e);
    }
  };

  const registerSuccess = () => {
    (document.getElementById("register-form") as HTMLFormElement).reset();
    setSuccess("Successfully registered!");
  };

  return (
    <>
      <div className="acc-container">
        <Register
          handleChange={handleRegisterChange}
          handleSubmit={handleSubmit}
        ></Register>
        <div className="divider"></div>
        <Login
          handleLogin={handleLogin}
          handleChange={handleLoginChange}
        ></Login>
      </div>
      <div className="success">{success}</div>
      <div className="error">{error}</div>
    </>
  );
}
