import "./style.scss";
import * as react from "react";
import { useCreateTournament } from "../../../../services/queries";
import Button from "@mui/material/Button";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { Select, MenuItem } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
const race_classes: string[] = ["G1", "G2", "G3", "G4", "Open", "Chance"];
export default function CreateTournament() {
  //create input fields for tournament info
  const createTournament = useCreateTournament();
  const [msg, setMsg] = react.useState("");
  const [t_name, setTournamentName] = react.useState("");
  const [t_class, setTournamentClass] = react.useState<number>();
  const [t_location, setTournamentLocation] = react.useState("");
  const [t_startTime, setTournamentDate] = react.useState("");
  const [t_cutoffTime, setCutoffTime] = react.useState("");
  const [t_fee, setTournamentFee] = react.useState<number>();
  const [t_prize, setTournamentPrize] = react.useState<number>();
  const [t_type, setTournamentType] = react.useState<string>("");
  const [type_index, setTypeIndex] = react.useState<number>(0);
  const handleSelectClass = (index: number) => {
    setTournamentClass(index);
  };

  const handleSubmit = async () => {
    setMsg("");
    if (!t_name) {
      setMsg("Please enter a name for the tournament");
      return;
    }
    if (!t_location) {
      setMsg("Please enter a location for the tournament");
      return;
    }
    if (t_class === undefined) {
      setMsg("Please select a class for the tournament");
      return;
    }
    // if (!t_startTime) {
    //   setMsg("Please select a start time for the tournament");
    //   return;
    // }
    if (!t_cutoffTime) {
      setMsg("Please select a cutoff time for the tournament");
      return;
    }
    if (type_index === 0) {
      setMsg("Please select a point type for the tournament");
      return;
    }
    let cutoffTime = moment(t_cutoffTime).utc(false).unix() * 1000;
    let data = {
      t_startTime: "",
      t_cutoffTime: cutoffTime,
      t_name: t_name,
      t_class: race_classes[t_class],
      t_location: t_location,
      t_fee,
      t_prize,
      t_type,
      t_pointStructure: tournamentPoints[type_index - 1].points,
    };
    console.log("submitting tournament", data);
    try {
      let response = await createTournament.mutateAsync(data);
      setMsg("Tournament created successfully");
    } catch (e: any) {
      console.log(e);
      setMsg(e.response.data);
    }
  };
  return (
    <>
      <div className="proto-container">
        <div className="">Create Tournament</div>
        <form style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="race_name">Name</label>
          <input
            type="text"
            name="race_name"
            onChange={(e) => setTournamentName(e.target.value)}
          ></input>
          <label htmlFor="race_location">Location</label>
          <input
            type="text"
            name="race_location"
            onChange={(e) => setTournamentLocation(e.target.value)}
          ></input>
          <label htmlFor="race_location">Tournament Point Type</label>
          <Select
            value={t_type}
            label="Point type"
            onChange={(e) => {
              let index = tournamentPoints
                .map((item) => item.name)
                .indexOf(e.target.value);
              setTournamentType(e.target.value);
              setTypeIndex(index + 1);
            }}
          >
            {tournamentPoints.map((p, index) => {
              return (
                <MenuItem value={p.name}>
                  {p.name} -{" "}
                  {p.points.map((p) => (
                    <>{p}, &nbsp;</>
                  ))}
                </MenuItem>
              );
            })}
          </Select>
          {/* <label htmlFor="sponsor_img">Sponsor Image Link</label>
          <input
            type="url"
            name="sponsor_img"
            placeholder="https://via.placeholder.com/150"
            onChange={(e) => setSponsorImage(e.target.value)}
          ></input>
          <label htmlFor="sponsor_msg">Sponsor Message</label>
          <input
            type="text"
            name="sponsor_msg"
            onChange={(e) => setSponsorMsg(e.target.value)}
          ></input>
          <label htmlFor="sponsor_link">Sponsor External Link</label>
          <input
            type="url"
            placeholder="https://example.com"
            name="sponsor_link"
            onChange={(e) => setSponsorLink(e.target.value)}
          ></input> */}
          <label htmlFor="entry_fee">Entry Fee (USDT)</label>
          <input
            type="number"
            name="entry_fee"
            pattern="/d+"
            onChange={(e) => setTournamentFee(parseInt(e.target.value))}
          ></input>
          <label htmlFor="prize_pool">Prize Pool (USDT)</label>
          <input
            type="number"
            name="prize_pool"
            pattern="/d+"
            onChange={(e) => setTournamentPrize(parseInt(e.target.value))}
          ></input>

          <label htmlFor="race_class">Class: </label>
          <div>
            {race_classes.map((race_class, index) => {
              return (
                <Button
                  sx={t_class === index ? { background: "green" } : {}}
                  variant="contained"
                  key={race_class}
                  onClick={() => handleSelectClass(index)}
                >
                  {race_class}
                </Button>
              );
            })}
          </div>

          <label htmlFor="race_time">Cutoff Time</label>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={t_cutoffTime}
            onChange={(newValue) => {
              setCutoffTime(newValue || "");
            }}
          />
        </form>
        <Button
          variant={"contained"}
          onClick={handleSubmit}
          style={{ margin: "0 auto", maxWidth: "200px" }}
        >
          Create Tournament
        </Button>
        <div className="msg">{msg}</div>
      </div>
    </>
  );
}

type TournamentPoints = {
  name: string;
  points: number[];
};

const tournamentPoints: TournamentPoints[] = [
  {
    name: "First to Line",
    points: [24, 20, 16, 12, 3, 3, 3, 3],
  },
  {
    name: "Even Up",
    points: [10, 11, 12, 12, 13, 14, 16, 18],
  },
  {
    name: "Slows the go",
    points: [11, 13, 14, 16, 18, 20, 22, 24],
  },
  {
    name: "Mid Field",
    points: [18, 19, 20, 24, 24, 18, 17, 15],
  },
];
