import { useQuery, useMutation } from "@tanstack/react-query";
import { ethers } from "ethers";
import { axiosInstance, BetNFTContracts } from "./../config/config";
import { TokenInfo } from "../types";
import axios from "axios";
export const useRegisterUser = () =>
  useMutation(["register"], (data: any) => {
    return axiosInstance.post("/users/register", data);
  });

export const useLogin = () =>
  useMutation(["login"], (data: any) => {
    return axiosInstance.post("/users/login", data);
  });

export const useTournament = () => {
  return useQuery(["tournament"], () => {
    return axiosInstance.get("/tournaments");
  });
};
export const useCurrentTournament = (tournamentId: string) => {
  return useQuery(
    [tournamentId],
    () => {
      return axiosInstance.get(`/tournaments/${tournamentId}`, {
        params: {
          raceId: tournamentId,
        },
      });
    },
    { refetchOnWindowFocus: false }
  );
};
export const useJoinTournament = () => {
  return useMutation(["join-tournament"], (data: any) => {
    return axiosInstance.post("/tournaments/join", data);
  });
};
export const useCreateTournament = () =>
  useMutation(["create-tournament"], (data: any) => {
    return axiosInstance.post("/tournaments/create", data);
  });

export const useAllRaces = () => {
  return useQuery(["racelist"], () => {
    return axiosInstance.get("/races");
  });
};

//for completed (successful) races
export const useCompletedRaces = () => {
  return useQuery(["finishedRaces"], () => {
    return axiosInstance.get("/races/completed");
  });
};

export const useCurrentRace = (raceId: string, onError?: () => void) => {
  return useQuery(
    [raceId],
    () => {
      return axiosInstance.get(`/races/${raceId}`, {
        params: {
          raceId: raceId,
        },
      });
    },
    { refetchOnWindowFocus: false, onError: onError }
  );
};

export const useUserHistory = (address: string, onError?: () => void) => {
  return useQuery(
    [address],
    () => {
      return axiosInstance.get(`/races/history/${address}`);
    },
    { refetchOnWindowFocus: false, onError: onError }
  );
};

export const useRaceHistory = () => {
  return useQuery(["raceHistory"], () => {
    return axiosInstance.get("/races/history");
  });
};
export const useRaceWaiting = () => {
  return useQuery(["raceWaiting"], () => {
    return axiosInstance.get("/races/waiting");
  });
};

export const useTokenInfo = (tokenId: string, tokenFamily: string) => {
  //${tokenFamily}/${tokenId}
  return useQuery(
    [tokenId + tokenFamily], //key
    () => {
      return axiosInstance.get(`/tokens/${tokenFamily}/${tokenId}`, {
        // params: {
        //   tokenFamily: tokenFamily,
        //   tokenId: tokenId,
        // },
      });
    }
  );
};

export const useHomeContent = () => {
  return useQuery(["homeContent"], () => {
    return axiosInstance.get("/content");
  });
};
export const useUpdateHomeContent = () => {
  return useMutation(["mint"], (data: any) => {
    return axiosInstance.post("/content", data);
  });
};

export const useKennelInfo = () =>
  useMutation(["kennel-info"], (data: any) => {
    return axiosInstance.post("/kennel/update", data);
  });

export const useChangeName = () =>
  useMutation(["changeName"], (data: any) => {
    return axiosInstance.post(`/tokens/rename/`, data);
  });

export const useCreateRace = () =>
  useMutation(["create-race"], (data: any) => {
    return axiosInstance.post("/races/create", data);
  });

export const useJoinRace = () =>
  useMutation(["join-race"], (data: any) => {
    return axiosInstance.post("/races/join", data);
  });

export const useStartRace = () =>
  useMutation(["start-race"], (data: any) => {
    return axiosInstance.post("/races/start", data);
  });

export const getTokensOwned = async (address: string, tokenFamily: string) => {
  try {
    const { ethereum } = window as any;

    if (ethereum) {
      const indexOfContract = BetNFTContracts.findIndex(
        (x) => x.name === tokenFamily
      );
      const tokenFamilyConfig = BetNFTContracts[indexOfContract];
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        tokenFamilyConfig.contract,
        tokenFamilyConfig.ABI,
        signer
      );

      let balance = await connectedContract.balanceOf(address);
      let tokens: TokenInfo[] = [];

      for (let i = 0; i < balance.toNumber(); i++) {
        let idx = await connectedContract.tokenOfOwnerByIndex(address, i);
        let data = await getTokenMetadata(idx.toNumber(), tokenFamily);
        tokens.push({
          tokenId: idx.toNumber(), //BN
          ownerAddress: address,
          // name: tokenData ? tokenData.name : "",
          tokenFamily: tokenFamily,
          metadata: data,
        } as TokenInfo);
      }
      console.log("getting tokens", tokens);
      return tokens;
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getTokenURI = async (
  tokenId: number,
  contract: ethers.Contract
) => {
  try {
    let uri = await contract.tokenURI(tokenId);

    console.log(uri);
    return uri;
  } catch (e) {
    console.log(e, "URI error");
  }
};

export const getTokenMetadata = async (
  tokenId: number,
  tokenFamily: string //LottoMint1, G1 - G4,
) => {
  if (!tokenFamily) {
    tokenFamily = "LottoMint1";
  }

  try {
    const tokenData = `https://betnft-dev.s3.ap-southeast-2.amazonaws.com/${tokenFamily}/${tokenId}.json`; //Lotto mint jsons
    //move to config file
    let res = await axios.get(tokenData, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    return res.data;
  } catch (e) {
    console.log(e, "URI error");
  }
};

export const getTokenImage = (imageLink: string) => {
  //"ipfs://QmRC7qTcDdRpEmaMWuxJvjQkYrSfM94xuq8tQhb7LFKQyT/260.png" trim this
  const trimmed = imageLink.substring(7, imageLink.length);
  const url = `https://betnft.mypinata.cloud/ipfs/${trimmed}`;

  return url;
};
