import * as react from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import {
  useAllRaces,
  useCompletedRaces,
  useRaceWaiting,
} from "../../../services/queries";
import CreateRace from "./createrace";
import RaceList from "./racelist";
import RaceDetails from "./racedetails";
import History from "./history";
import ResultList from "./resultlist";
import Menu from "../../common/Menu";
import TournamentList from "../races/tournaments";
import Tournament from "../races/tournaments/main";
import CreateTournament from "../races/tournaments/create";
import { Race, TokenInfo } from "../../../types";
import "./style.scss";
interface IProps {
  ownedTokens?: TokenInfo[];
}

const menuTabs: string[] = [
  "Events",
  "Tournaments",
  "Race Results",
  "My Race History",
  "Create Event",
  "Create Tournament",
];
const links: string[] = [
  "",
  "/tournaments",
  "/results",
  "/history",
  "/create",
  "/create/tournament",
];
export default function RaceContent({ ownedTokens }: IProps) {
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = react.useState(0);
  const [raceIndex, setRaceIndex] = react.useState(0);
  const { data, isLoading, isFetching, refetch } = useAllRaces();
  const raceHistory = useCompletedRaces();
  const raceWaiting = useRaceWaiting();
  const [showRaceDetails, setShowRaceDetails] = react.useState(false);

  const handleTabChange = (index: number) => {
    setCurrentTab(index);
    setShowRaceDetails(false);
    refetch();
    raceHistory.refetch();
    raceWaiting.refetch();
  };

  react.useEffect(() => {
    if (pathname === "/race") refetch();
  }, [pathname]);
  return (
    <>
      <Menu
        tabs={menuTabs}
        links={links}
        currentTab={currentTab}
        handleTabChange={handleTabChange}
      ></Menu>

      <Routes>
        <Route path="/:uuid" element={<RaceDetails></RaceDetails>}></Route>
        <Route
          path="/tournaments/:uuid"
          element={<Tournament></Tournament>}
        ></Route>
        <Route
          path="/tournaments"
          element={<TournamentList></TournamentList>}
        ></Route>

        <Route path="/create" element={<CreateRace />}></Route>
        <Route path="/create/tournament" element={<CreateTournament />}></Route>
        <Route
          path="/results/:uuid"
          element={
            <ResultList
              data={data}
              nextToRunData={raceWaiting.data}
              isLoading={isFetching}
            />
          }
        ></Route>
        <Route
          path="/results"
          element={
            <ResultList
              nextToRunData={raceWaiting.data}
              data={raceHistory.data}
              isLoading={raceHistory.isFetching}
            />
          }
        ></Route>
        <Route
          path="/history"
          element={
            <History
              data={raceWaiting.data}
              isLoading={raceWaiting.isFetching}
            ></History>
          }
        ></Route>
        <Route
          path="/"
          element={
            <RaceList
              data={raceWaiting.data}
              nextToRunData={raceWaiting.data}
              isLoading={raceWaiting.isFetching}
            ></RaceList>
          }
        ></Route>
      </Routes>
    </>
  );
}
