import * as react from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useAppSelector } from "../../../../app/hooks";
import { selectAccount, selectAllTokens } from "../../../../store/mainSlice";
import { Race } from "../../../../types";
import { Routes, Route, Link } from "react-router-dom";
import InfoWhite from "../../../assets/info-white.png";
import { OrdinalMap } from "../../../utils";
import { getTimeToRace } from "../../../../tools/helper";
import { useUserHistory } from "../../../../services/queries";
import "./style.scss";
interface IProps {
  data: any;

  isLoading?: boolean;
  isError?: boolean;
}

export default function UserHistory(props: IProps) {
  const userAddress = useAppSelector(selectAccount);
  const allTokens = useAppSelector(selectAllTokens);
  const { data: historyData } = useUserHistory(userAddress);

  const [showSummary, setShowSummary] = react.useState(false);
  const [races, setRaces] = react.useState<Race[]>([]);
  const { data } = props;
  const { uuid } = useParams(); //RaceID

  react.useEffect(() => {
    console.log(uuid, "uuid");
    if (uuid) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [uuid]);
  const [timer, setTimer] = react.useState(0);
  react.useEffect(() => {
    //re-render this component every second
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  react.useEffect(() => {
    if (data && data.data.length > 0 && Array.isArray(data.data)) {
      //function to sort data by startTime property
      let copy = data.data.filter((race: Race) => !race.cancelled);
      const sortData = (copy as Race[]).sort(function (a, b) {
        return a.startTime - b.startTime;
      });

      setRaces(sortData);
    }
  }, [data]);

  let count = 0;
  return (
    <>
      <div className="racelist">
        <div className="topbar">
          <span className="sides">Next to Run</span>
          <div className="next">
            {/* TODO: Sort by time left */}
            {races.length > 0 ? (
              races.map((race: Race, index: number) => {
                if (
                  !race.completed ||
                  Date.now() < race.raceTime! * 1000 + race.startTime
                ) {
                  count += 1;
                  let countdown = getTimeToRace(race.startTime);
                  if (race.cancelled) {
                    return;
                  }
                  if (count <= 5)
                    return (
                      <Link
                        to={`/race/${race.raceId}`}
                        className="box"
                        key={race.raceId}
                      >
                        <div className="race-name">{race.raceName}</div>
                        <div className="time">
                          <span className="start">Starts:</span>
                          <span className="clock">
                            {Date.now() < race.startTime
                              ? `${
                                  countdown.hours ? countdown.hours + "h" : ""
                                } ${
                                  countdown.minutes
                                    ? countdown.minutes + "m"
                                    : ""
                                } ${
                                  countdown.seconds
                                    ? countdown.seconds + "s"
                                    : ""
                                }`
                              : "Live"}
                          </span>
                        </div>
                      </Link>
                    );
                }
              })
            ) : (
              <div className="none">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>
          <span className="sides">See All</span>
        </div>
        <div className="race-classes">
          <div className="title">
            MY RACING HISTORY<img src={InfoWhite}></img>
          </div>
        </div>
        <div className="history-list">
          <div className="titles summary-columns">
            <div>PLACE</div>
            <div>GREYHOUND</div>
            <div>RACE NAME</div>
            <div>DISTANCE</div>
            <div>DATE</div>
          </div>

          {!showSummary && (
            <>
              <div className="history">
                {Array.isArray(historyData?.data) ? (
                  historyData?.data.map((race: Race, index: number) => {
                    return race.entrants.map((entrant: any, index2: any) => {
                      if (entrant.ownerAddress === userAddress) {
                        let outcomePosition =
                          race.outcome?.findIndex(
                            (item: any) =>
                              item.tokenId === entrant.tokenId &&
                              item.tokenFamily === entrant.tokenFamily
                          )! + 1;
                        let token = allTokens.find(
                          (item) =>
                            item.tokenId === entrant.tokenId &&
                            item.tokenFamily === entrant.tokenFamily
                        );
                        if (race.cancelled) {
                          outcomePosition = 0;
                        }
                        return (
                          <Link
                            to={`/race/${race.raceId}`}
                            className="history-row"
                            key={race.raceId + index2}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div className="grid-item ">
                              <div className="position">
                                {outcomePosition
                                  ? OrdinalMap[
                                      outcomePosition as keyof typeof OrdinalMap
                                    ]
                                  : "A"}
                              </div>
                            </div>
                            <div className="name grid-item">
                              {token?.metadata!.name}
                            </div>
                            <div className="race-titles">
                              <span>
                                {race.raceName}
                                {race.cancelled && " - Abandoned"}
                              </span>
                            </div>

                            <div className="distance grid-item">
                              {race.raceDistance}m
                            </div>
                            <div className="date grid-item">
                              {moment(race.startTime).format(
                                "DD MMM, YYYY. hh:mm"
                              )}
                            </div>
                          </Link>
                        );
                      }
                    });
                  })
                ) : (
                  <div className="none">
                    {props.isLoading ? "Loading races..." : "No Races Found"}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
