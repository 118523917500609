import * as react from "react";
import Button from "@mui/material/Button";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { useUpdateHomeContent } from "../../../../services/queries";

export default function UpdateHome() {
  const updateHomeContent = useUpdateHomeContent();
  const [content, setContent] = react.useState("");
  const [statusMsg, setStatusMsg] = react.useState("");

  const handleSubmitContent = async () => {
    try {
      setStatusMsg("Updating...");
      let result = await updateHomeContent.mutateAsync({
        content: content,
      });
      setStatusMsg("Updated!");
      console.log(result.data);
    } catch (e: any) {
      setStatusMsg("Error updating..");
      console.log(e, "ERROR");
    }
  };
  return (
    <>
      <div
        style={{
          margin: "0.5rem",
          width: "25%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label htmlFor="content">Content</label>
          <input
            type="text"
            name="content"
            onChange={(e) => setContent(e.target.value)}
          ></input>
        </form>
        <Button
          variant={"contained"}
          onClick={handleSubmitContent}
          style={{ margin: "25px auto", maxWidth: "200px" }}
        >
          Update Home Content
        </Button>
        <div>{statusMsg}</div>
      </div>
    </>
  );
}
