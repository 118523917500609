import * as react from "react";
import Home from "./contents/home";
import Race from "./contents/races/race";
import Kennel from "./contents/kennel";
import Account from "./account";
import Breeding from "./contents/breeding";
import Marketplace from "./contents/marketplace";
import Rental from "./contents/rental";
import { Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./style.scss";
interface IProps {
  address: string;
  networkId: string;
}
export default function Content({ address, networkId }: IProps) {
  const { pathname } = useLocation();

  return (
    <>
      <div className="content" id="content">
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        {pathname !== "/" && (
          <div className="content-padding">
            <Routes>
              <Route path="/race/*" element={<Race></Race>} />

              <Route path="/kennel" element={<Kennel></Kennel>} />
              <Route path="/account" element={<Account></Account>} />
              <Route path="/breeding" element={<Breeding></Breeding>} />
              <Route
                path="/marketplace"
                element={<Marketplace></Marketplace>}
              />
              <Route path="/rental" element={<Rental></Rental>} />
            </Routes>
          </div>
        )}
      </div>
    </>
  );
}
