import * as react from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import Button from "@mui/material/Button";
import LogoWShadow from "../../assets/logowshadow.png";
interface MenuProps {
  tabs: string[];
  links: string[];
  currentTab: number;
  handleTabChange: (index: number) => void;
}
export default function Menu(props: MenuProps) {
  const { tabs, currentTab, handleTabChange, links } = props;
  const navigate = useNavigate();
  const handleOnClick = react.useCallback(
    (tab: string) => navigate(`/race${tab}`, { replace: true }),
    [navigate]
  );
  return (
    <div className="menu">
      {props.tabs &&
        props.tabs.map((tab, index) => (
          <Button
            className="option"
            key={index}
            onClick={() => handleOnClick(links[index])}
          >
            {props.tabs![index]}
          </Button>
        ))}
      <img src={LogoWShadow} className="logo"></img>
    </div>
  );
}
