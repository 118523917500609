import "./style.scss";
import * as react from "react";
import { Link } from "react-router-dom";
import { useTournament, useJoinTournament } from "../../../../services/queries";

export default function Tournaments() {
  const allTournaments = useTournament();

  const { data, isSuccess } = allTournaments;

  const [show, setShow] = react.useState<boolean[]>([]);

  react.useEffect(() => {
    if (!data) return;
    let update = [...show];
    for (let i = 0; i < data.data.length; i++) {
      if (i >= show.length) {
        update.push(false);
        continue;
      }
    }
    setShow(update);
  }, [data]);

  return (
    <>
      <div className="tournament">
        {allTournaments.isLoading ? (
          "Loading information..."
        ) : (
          <>
            <div className="">
              <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Class</th>
                    <th>Fee</th>
                    <th>Prize</th>
                    <th>Start</th>
                    <th>Status</th>
                    <th>Races</th>
                    <th>Entrants</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isSuccess ? (
                    (data.data as any[]).map((t, index) => {
                      return (
                        <>
                          <tr
                            key={t.id}
                            style={{
                              border: "1px solid blue",
                            }}
                          >
                            <td>{t.id}</td>
                            <td>{t.name}</td>
                            <td>{t.location}</td>
                            <td>{t.t_class}</td>
                            <td>{t.entryFee}</td>
                            <td>{t.prizePool}</td>
                            <td>{t.cutoffTime}</td>
                            <td>{t.status}</td>
                            <td className="t-races">
                              {t.races.map((r: any) => {
                                return <div>{r}</div>;
                              })}
                            </td>
                            <td className="t-entrants">{t.entrants.length}</td>
                            <td>
                              <Link
                                className="show"
                                to={`/race/tournaments/${t.id}`}
                              >
                                Go To
                              </Link>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>No Data</>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}
