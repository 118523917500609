import "./style.scss";
import * as react from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  selectAccount,
  selectSignature,
  selectLottoMint1Tokens,
  selectG1Tokens,
  selectG2Tokens,
  selectG3Tokens,
  selectG4Tokens,
  updateTokenInfo,
  selectNonce,
  TokenFamily,
} from "../../../store/mainSlice";
import { Button } from "@mui/material";
import InfoIcon from "../../assets/info.png";
import Naming from "./naming";
import SearchIcon from "../../assets/search.svg";
import FilterIcon from "../../assets/filter-results-button.png";
import DownArrow from "../../assets/down-arrow.svg";
import PlaceholderPic from "../../assets/placeholderpic.png";
import KennelSettings from "./kennelsettings";
import Popup from "../../common/Popup";
import PlaceholderDog from "../../assets/placeholderdog.png";
import LogoWShadow from "../../assets/logowshadow.png";
import Clipboard from "../../assets/clipboard.svg";
import PlaceholderDetailDog from "../../assets/placeholderdetaildog.png";
import DetailIcon from "../../assets/leaping.png";
import BreedIcon from "../../assets/paw.png";
import Cog from "../../assets/cog.svg";
import { TokenInfo } from "../../../types";

import {
  getTokenImage,
  useChangeName,
  useKennelInfo,
  useTokenInfo,
} from "../../../services/queries";
import { signMessage } from "../../../tools/wallet";
import { getBloodlineCount } from "../../../tools/helper";
const NumberMap = {
  "1": "One",
  "2": "Two",
  "3": "Three",
  "4": "Four",
};

export default function Kennel() {
  const [showDetails, setShowDetails] = react.useState<boolean>(false);
  const [showNamingPopup, setShowNamingPopup] = react.useState<boolean>(false);
  const [showKennelSettings, setShowKennelSettings] =
    react.useState<boolean>(false);
  const [dataIndex, setDataIndex] = react.useState<number>(0);
  const [error, setError] = react.useState<string>("");

  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const nonce = useAppSelector(selectNonce);
  const signature = useAppSelector(selectSignature);
  const LottoMint1Tokens = useAppSelector(selectLottoMint1Tokens);
  const G1Tokens = useAppSelector(selectG1Tokens);
  const G2Tokens = useAppSelector(selectG2Tokens);
  const G3Tokens = useAppSelector(selectG3Tokens);
  const G4Tokens = useAppSelector(selectG4Tokens);

  const [context, setContext] = react.useState<TokenInfo[]>([]);
  const [detailFamily, setDetailFamily] = react.useState<string | undefined>();

  const testToggle = (tokenFamily: string, index: number) => {
    if (tokenFamily === "LottoMint1") {
      setDetailFamily("LottoMint1");
      setContext(LottoMint1Tokens!);
    } else if (tokenFamily === "G1") {
      setContext(G1Tokens!);
      setDetailFamily("G1");
    } else if (tokenFamily === "G2") {
      setContext(G2Tokens!);
      setDetailFamily("G2");
    } else if (tokenFamily === "G3") {
      setContext(G3Tokens!);
      setDetailFamily("G3");
    } else if (tokenFamily === "G4") {
      setContext(G4Tokens!);
      setDetailFamily("G4");
    }
    setShowDetails(!showDetails);
    window.scrollTo(0, 0);
    setDataIndex(index);
  };
  const kennelInfo = useKennelInfo();

  const changeName = useChangeName();

  const updateKennelInfo = react.useCallback(async () => {
    //data for kennel is address and signature
    try {
      if (account && signature) {
        console.log("checking user");
        let data = {
          address: account,
          signature: signature,
        };
        console.log(kennelInfo.data); //TODO: maybe store in redux
        if (!kennelInfo.data) {
          kennelInfo.mutate(data);
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  }, [account, signature]);
  react.useEffect(() => {
    updateKennelInfo();
  }, [account, signature]);

  react.useEffect(() => {
    if (detailFamily === "LottoMint1") {
      setContext(LottoMint1Tokens!);
    } else if (detailFamily === "G1") {
      setContext(G1Tokens!);
    } else if (detailFamily === "G2") {
      setContext(G2Tokens!);
    } else if (detailFamily === "G3") {
      setContext(G3Tokens!);
    } else if (detailFamily === "G4") {
      setContext(G4Tokens!);
    }
  }, [detailFamily, LottoMint1Tokens, G1Tokens, G2Tokens, G3Tokens, G4Tokens]);

  const handleSubmitName = async (
    tokenFamily: string,
    tokenId: number,
    name: string
  ) => {
    if (name && name.length >= 4) {
      let sig = await signMessage(nonce);
      try {
        setError("");
        let data = {
          signature: sig,
          message: "Sign this to enter BETNFT.",
          address: account,
          ownerAddress: account,
          name: name,
          tokenId: tokenId,
          tokenFamily: tokenFamily,
        };
        let res = await changeName.mutateAsync(data);
        //const res = await axiosInstance.post("/tokens/rename", data);
        dispatch(updateTokenInfo(tokenFamily));
        setShowNamingPopup(false);
      } catch (e: any) {
        console.log(e, "Error");
        setError(e.response.data);
      }
    } else {
      setError("Name must be at least 4 characters long.");
    }
  };

  const handleSubmitKennelName = async (name: string, email: string) => {
    if (name && name.length >= 4) {
      let sig = await signMessage(nonce);
      try {
        setError("");
        let data = {
          signature: sig,
          message: "Sign this to enter BETNFT.",
          address: account,
          ownerAddress: account,
          name: name,
          email: email,
        };
        await kennelInfo.mutateAsync(data);
        setShowKennelSettings(false);
      } catch (e: any) {
        console.log(e, "Error");
        setError(e.response.data);
      }
    } else {
      setError("Name must be at least 4 characters long.");
    }
  };

  const toggleNamingPopup = () => {
    setError("");
    setShowNamingPopup(!showNamingPopup);
  };
  const toggleSettingsPopup = () => {
    setError("");
    setShowKennelSettings(!showKennelSettings);
  };
  return (
    <>
      {showNamingPopup && (
        <Popup>
          <Naming
            handleSubmitName={handleSubmitName}
            tokenData={context![dataIndex]}
            error={error}
            handleClose={toggleNamingPopup}
            mutation={changeName}
          ></Naming>
        </Popup>
      )}
      {showKennelSettings && (
        <Popup>
          <KennelSettings
            mutation={kennelInfo}
            handleSubmitName={handleSubmitKennelName}
            error={error}
            handleClose={toggleSettingsPopup}
          ></KennelSettings>
        </Popup>
      )}

      <div
        className="kennel-main"
        style={{
          position:
            showNamingPopup || showKennelSettings ? "fixed" : "relative",
        }}
      >
        <div className="top-tag dark-green">
          <span onClick={() => setShowDetails(false)}>OWNER'S KENNEL</span>
        </div>
        <div className="kennel-header ">
          <img src={PlaceholderPic} className="picture"></img>
          {kennelInfo.data?.data.name
            ? kennelInfo.data.data.name
            : "Loading..."}
          <img src={Clipboard} className="clipboard"></img>
          <div
            className="settings"
            onClick={() => {
              if (signature) setShowKennelSettings(true);
            }}
          >
            <img src={Cog}></img>Settings
          </div>
        </div>
        <div className="kennel-content">
          {showDetails ? (
            <KennelDetail
              tokenData={context![dataIndex]}
              submitName={handleSubmitName}
              toggleNamingPopup={toggleNamingPopup}
            ></KennelDetail>
          ) : (
            <>
              <div className="stat-header">
                <span>BLOODLINES</span>
                <span>CAREER STATS</span>
                <span>WIN PERCENTAGE</span>
              </div>
              <div className="stat-info dark-green">
                <span>
                  {
                    NumberMap[
                      getBloodlineCount(
                        LottoMint1Tokens.concat(
                          G1Tokens.concat(
                            G2Tokens.concat(G3Tokens.concat(G4Tokens))
                          )
                        )
                      ).toString() as keyof typeof NumberMap
                    ]
                  }{" "}
                  (
                  {getBloodlineCount(
                    LottoMint1Tokens.concat(
                      G1Tokens.concat(
                        G2Tokens.concat(G3Tokens.concat(G4Tokens))
                      )
                    )
                  )}
                  )
                </span>
                <span>
                  {kennelInfo.data
                    ? `${kennelInfo.data?.data.career.firsts}/${kennelInfo.data?.data.career.seconds}/${kennelInfo.data?.data.career.thirds}`
                    : "Loading..."}
                </span>
                <span>
                  {kennelInfo.data
                    ? `${(kennelInfo.data?.data.completed_races != 0
                        ? (kennelInfo.data?.data.career.firsts * 100) /
                          kennelInfo.data?.data.completed_races
                        : 0
                      ).toFixed(2)}%`
                    : "Loading..."}
                </span>
              </div>
              <div className="holder">
                <div className="kennel-info">
                  <div className="kennel-info-header">
                    GREYHOUND KENNEL BREEDS
                  </div>
                  <div className="filters dark-green">
                    <div className="container-box ">
                      <img src={SearchIcon} className="search-icon"></img>
                      <input type="text" placeholder="SEARCH"></input>
                    </div>

                    <div className="filter container-box ">
                      <img src={FilterIcon} className="filter-icon icon"></img>
                      Filters
                    </div>
                    <div className="sort">
                      <span className="sort-by">SORT BY:</span>
                      <div className="container-box dark-green">
                        DATE- NEWEST{" "}
                        <img
                          src={DownArrow}
                          className="downarrow-icon icon"
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="dogs">
                    {LottoMint1Tokens && (
                      <KennelList
                        tokenData={LottoMint1Tokens}
                        showDetails={testToggle}
                      ></KennelList>
                    )}
                    <div>G1 Tokens</div>
                    {G1Tokens && (
                      <KennelList
                        tokenData={G1Tokens}
                        showDetails={testToggle}
                      ></KennelList>
                    )}
                    <div>G2 Tokens</div>
                    {G2Tokens && (
                      <KennelList
                        tokenData={G2Tokens}
                        showDetails={testToggle}
                      ></KennelList>
                    )}
                    <div>G3 Tokens</div>
                    {G3Tokens && (
                      <KennelList
                        tokenData={G3Tokens}
                        showDetails={testToggle}
                      ></KennelList>
                    )}
                    <div>G4 Tokens</div>
                    {G4Tokens && (
                      <KennelList
                        tokenData={G4Tokens}
                        showDetails={testToggle}
                      ></KennelList>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          <img src={LogoWShadow} className="logo-shadow"></img>
        </div>
      </div>
    </>
  );
}

interface KennelListProps {
  tokenData: TokenInfo[];
  showDetails: (tokenFamily: string, index: number) => void;
}

function KennelList({ tokenData, showDetails }: KennelListProps) {
  return (
    <>
      {tokenData &&
        tokenData?.map((token: TokenInfo, index: number) => {
          let bloodlineAttrIndex = token.metadata?.attributes.findIndex(
            (attr) =>
              attr.trait_type === "Bloodline" || attr.trait_type === "bloodline"
          );
          let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
            token.metadata?.attributes[bloodlineAttrIndex!].value
          );
          return (
            <div
              className="row"
              onClick={() => showDetails(token.tokenFamily!, index)}
              key={token.tokenId}
            >
              <div className="img-container">
                <img
                  src={
                    token.metadata
                      ? getTokenImage(token.metadata?.image)
                      : PlaceholderDog
                  }
                ></img>
              </div>

              {/* <img src={DividerTest} className="divider"></img> */}
              <span className="dog-name">
                {/* TODO: Use token name  */}
                {token.metadata?.name}
              </span>
              <span className="bloodline">
                {bloodlineTag + " - " + bloodlineName}
              </span>
              <Fatigue value={100}></Fatigue>
              <span className="end">Q 0/0</span>
            </div>
          );
        })}
    </>
  );
}

interface KennelProps {
  tokenData: TokenInfo;
  submitName: (tokenFamily: string, tokenId: number, name: string) => void;
  toggleNamingPopup: () => void;
}

function KennelDetail(props: KennelProps) {
  const { tokenData, submitName, toggleNamingPopup } = props;
  let bloodlineAttrIndex = tokenData.metadata?.attributes.findIndex(
    (attr) => attr.trait_type === "Bloodline"
  );
  let breedLevelAttrIndex = tokenData.metadata?.attributes.findIndex(
    (attr) => attr.trait_type === "Breed level"
  );
  let genderAttrIndex = tokenData.metadata?.attributes.findIndex(
    (attr) => attr.trait_type === "Gender"
  );
  let coatColorAttrIndex = tokenData.metadata?.attributes.findIndex(
    (attr) =>
      attr.trait_type === "Coat Colour" || attr.trait_type === "Coat Colour4"
  );
  let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
    tokenData.metadata?.attributes[bloodlineAttrIndex!].value
  );
  const [nameInput, setNameInput] = react.useState<string>("");
  const tokenInfo = useTokenInfo(
    tokenData.tokenId.toString(),
    tokenData.tokenFamily
  );

  return (
    <>
      <div className="detail-header">
        <div>
          {tokenData.metadata?.name}
          {/* TODO: Use different metadata to check naming instead of just UI */}
          {tokenData.metadata?.name.match(/Greyhound #*/) && (
            <Button
              variant={"contained"}
              onClick={() => toggleNamingPopup()}
              style={{ marginLeft: "15px", background: "var(--main-green)" }}
            >
              Change Name
            </Button>
          )}
        </div>
        {/* <span className="dog-class">Class 03</span>{" "} */}
      </div>
      <div className="kennel-detail">
        <div className="detail-content">
          <div className="dog-image">
            <img
              src={
                tokenData.metadata
                  ? getTokenImage(tokenData.metadata?.image)
                  : PlaceholderDog
              }
              alt={tokenData.metadata?.name || "dog"}
            ></img>
          </div>
          <Fatigue value={100}></Fatigue>
          <div className="extra-icons">
            <div className="group">
              DETAILS <img src={DetailIcon}></img>
            </div>
            <div className="group">
              BREED <img src={BreedIcon}></img>
            </div>
          </div>
        </div>
        <div className="detailed-info">
          <div className="detail">
            <span className="title">BREED LEVEL</span>
            <span className="value">
              {tokenData.metadata?.attributes[breedLevelAttrIndex!].value}
            </span>
          </div>
          <div className="detail">
            <span className="title">BLOODLINE</span>
            <span className="value">
              {bloodlineTag} - {bloodlineName}
            </span>
          </div>
          <div className="detail">
            <span className="title">GENDER</span>
            <span className="value">
              {tokenData.metadata?.attributes[genderAttrIndex!].value}
            </span>
          </div>
          <div className="detail">
            <span className="title">COAT</span>
            <span className="value">
              {tokenData.metadata?.attributes[coatColorAttrIndex!].value}
            </span>
          </div>
          <div className="detail">
            <span className="title">RACES</span>
            <span className="value">
              {tokenInfo.data?.data.completed_races || 0}
            </span>
          </div>
          <div className="detail">
            <span className="title">CAREER STATS</span>
            <span className="value">
              {tokenInfo.data
                ? `${tokenInfo.data?.data.career.firsts}/${tokenInfo.data?.data.career.seconds}/${tokenInfo.data?.data.career.thirds}`
                : "0/0/0"}
            </span>
          </div>
          <div className="detail">
            <span className="title">PUPS</span>
            <span className="value">
              0
              <span className="dark-green highlight">
                &nbsp;- 00/00 LEFT <img src={InfoIcon}></img>
              </span>
            </span>
          </div>
          <div className="detail">
            <span className="title">BREEDING DECAY</span>
            <span className="value">
              Level 0
              <span className="dark-green  highlight">
                &nbsp;- 00/00 <img src={InfoIcon}></img>
              </span>
            </span>
          </div>
          <div className="detail">
            <span className="title">WIN PERCENTAGE</span>
            <span className="value">
              {`${(tokenInfo.data
                ? (tokenInfo.data?.data.career.firsts * 100) /
                  tokenInfo.data?.data.completed_races
                : 0
              ).toFixed(2)}%`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export function trimBloodlineAttribute(bloodline: string) {
  return [bloodline.substring(0, 2), bloodline.substring(3, bloodline.length)];
}

interface FatigueProps {
  value: string | number;
}

function Fatigue({ value }: FatigueProps) {
  return (
    <>
      <div className="fatigue">
        Fatigue
        <div className="gradient-slider">
          <div className="indicator"></div>
        </div>
        {value}
      </div>
    </>
  );
}
