import * as react from "react";
import { Race } from "../../../types";
import moment from "moment";
import validator from "validator";
import { Routes, Route, Link } from "react-router-dom";
import InfoWhite from "../../assets/info-white.png";
import { getTimeToRace } from "../../../tools/helper";
interface IProps {
  data: any;
  nextToRunData: any;
  isLoading?: boolean;
  isError?: boolean;
}

export default function Racelist(props: IProps) {
  const { data, nextToRunData } = props;
  const [timer, setTimer] = react.useState(0);
  const [races, setRaces] = react.useState<Race[]>([]);

  let count = 0;
  react.useEffect(() => {
    //re-render this component every second
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  react.useEffect(() => {
    if (
      nextToRunData &&
      nextToRunData.data.length > 0 &&
      Array.isArray(nextToRunData.data)
    ) {
      //function to sort data by startTime property
      let copy = nextToRunData.data.filter((race: Race) => !race.cancelled);
      const sortData = (copy as Race[]).sort(function (a, b) {
        return a.startTime - b.startTime;
      });

      setRaces(sortData);
    }
  }, [nextToRunData]);

  return (
    <>
      <div className="racelist">
        <div className="topbar">
          <span className="sides">Next to Run</span>
          <div className="next">
            {/* TODO: Sort by time left */}
            {races.length > 0 ? (
              races.map((race: Race, index: number) => {
                if (
                  !race.completed ||
                  Date.now() < race.raceTime! * 1000 + race.startTime
                ) {
                  count += 1;
                  let countdown = getTimeToRace(race.startTime);
                  if (race.cancelled) {
                    console.log(race.raceId, "race cancelled");
                    return;
                  }

                  if (count <= 5)
                    return (
                      <Link
                        to={`/race/${race.raceId}`}
                        className="box"
                        key={race.raceId}
                      >
                        <div className="race-name">{race.raceName}</div>
                        <div className="time">
                          <span className="start">Starts:</span>
                          <span className="clock">
                            {Date.now() < race.startTime
                              ? `${
                                  countdown.hours ? countdown.hours + "h" : ""
                                } ${
                                  countdown.minutes
                                    ? countdown.minutes + "m"
                                    : ""
                                } ${
                                  countdown.seconds
                                    ? countdown.seconds + "s"
                                    : ""
                                }`
                              : "Live"}
                          </span>
                        </div>
                      </Link>
                    );
                }
              })
            ) : (
              <div className="none">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>

          <span className="sides">See All</span>
        </div>
        <div className="race-classes">
          <div className="title">
            RACES TO ENTER <img src={InfoWhite}></img>
          </div>
          <div className="classes">
            <div className="class-filter ">Chance </div>
            <div className="class-filter ">Open</div>
            <div className="class-filter ">Class G1</div>
            <div className="class-filter ">Class G2</div>
            <div className="class-filter ">Class G3</div>
            <div className="class-filter ">Class G4</div>
          </div>
        </div>
        <div className="list">
          <div className="titles">
            <div>Race Event</div>
            <div>Event Type</div>
            <div>Distance</div>
            <div>Entry Fee</div>
            <div>Prize Pool</div>
            <div>Registered</div>
          </div>

          <div className="races">
            {Array.isArray(data?.data) ? (
              data?.data.map((race: Race, index: number) => {
                if (Date.now() < race.startTime && !race.cancelled)
                  return (
                    <Link
                      to={`/race/${race.raceId}`}
                      className="race-row"
                      key={race.raceId}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <div className="race-titles">
                        <span>{race.raceName}</span>

                        <span>{race.raceLocation || "BetNFT"}</span>
                      </div>

                      <div className="grid-item">
                        <ClassIndicator>{race.raceClass}</ClassIndicator>
                      </div>

                      <div className="distance grid-item">
                        {race.raceDistance}m
                      </div>
                      <div className="entry-fee grid-item">
                        {!race.entryFee ? "Free" : `$${race.entryFee} USDT`}
                      </div>
                      <div className="prize-pool grid-item">
                        ${race.prizePool} USDT
                      </div>
                      <div className="registered-racers grid-item">
                        {race.entrants ? `${race.entrants.length}/${8}` : "0/8"}
                      </div>
                    </Link>
                  );
              })
            ) : (
              <div className="none">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

interface IndicatorProps {
  children?: react.ReactNode;
}
export function ClassIndicator(props: IndicatorProps) {
  return <div className="event-type">{props.children || "Open"}</div>;
}
