import * as react from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import validator from "validator";
import { Race } from "../../../types";
import { Routes, Route, Link } from "react-router-dom";
import InfoWhite from "../../assets/info-white.png";
import RaceSummary from "./racesummary";
import { getTimeToRace } from "../../../tools/helper";
interface IProps {
  data: any;
  nextToRunData: any;
  isLoading?: boolean;
  isError?: boolean;
}

export default function Resultlist(props: IProps) {
  const { data, nextToRunData } = props;
  const [showSummary, setShowSummary] = react.useState(false);
  const [races, setRaces] = react.useState<Race[]>([]);
  const { uuid } = useParams(); //RaceID
  const [currentRaceIndex, setCurrentRaceIndex] = react.useState<
    number | undefined
  >(undefined);

  react.useEffect(() => {
    console.log(uuid, "uuid");
    if (uuid) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }

    if (data?.data.length > 0 && uuid) {
      const raceIndex = data?.data.findIndex(
        (item: any) => item.raceId === uuid
      );
      console.log(raceIndex, "raceIndex");
      if (raceIndex >= 0) {
        setCurrentRaceIndex(raceIndex);
      } else {
        setCurrentRaceIndex(undefined);
      }
    }
  }, [uuid, data]);
  const [timer, setTimer] = react.useState(0);
  react.useEffect(() => {
    //re-render this component every second
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  react.useEffect(() => {
    if (
      nextToRunData &&
      nextToRunData.data.length > 0 &&
      Array.isArray(nextToRunData.data)
    ) {
      //function to sort data by startTime property
      let copy = nextToRunData.data.filter((race: Race) => !race.cancelled);
      const sortData = (copy as Race[]).sort(function (a, b) {
        return a.startTime - b.startTime;
      });

      setRaces(sortData);
    }
  }, [nextToRunData]);
  let count = 0;
  return (
    <>
      <div className="racelist">
        <div className="topbar">
          <span className="sides">Next to Run</span>
          <div className="next">
            {/* TODO: Sort by time left */}
            {races.length > 0 ? (
              races.map((race: Race, index: number) => {
                if (
                  !race.completed ||
                  Date.now() < race.raceTime! * 1000 + race.startTime
                ) {
                  count += 1;
                  let countdown = getTimeToRace(race.startTime);
                  if (race.cancelled) {
                    return;
                  }
                  if (count <= 5)
                    return (
                      <Link
                        to={`/race/${race.raceId}`}
                        className="box"
                        key={race.raceId}
                      >
                        <div className="race-name">{race.raceName}</div>
                        <div className="time">
                          <span className="start">Starts:</span>
                          <span className="clock">
                            {Date.now() < race.startTime
                              ? `${
                                  countdown.hours ? countdown.hours + "h" : ""
                                } ${
                                  countdown.minutes
                                    ? countdown.minutes + "m"
                                    : ""
                                } ${
                                  countdown.seconds
                                    ? countdown.seconds + "s"
                                    : ""
                                }`
                              : "Live"}
                          </span>
                        </div>
                      </Link>
                    );
                }
              })
            ) : (
              <div className="none">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>
          <span className="sides">See All</span>
        </div>
        <div className="race-classes">
          <div className="title">
            RESULTS<img src={InfoWhite}></img>
          </div>
          <div className="classes">
            <div className="class-filter ">Chance</div>
            <div className="class-filter ">Open</div>
            <div className="class-filter ">Class G1</div>
            <div className="class-filter ">Class G2</div>
            <div className="class-filter ">Class G3</div>
            <div className="class-filter ">Class G4</div>
          </div>
        </div>
        <div className="list">
          <div className="titles summary-columns">
            <div>RACE NAME</div>
            <div>Event</div>
            <div>Distance</div>
            <div>Date</div>
            <div>Prize Pool</div>
          </div>

          {showSummary &&
            data &&
            currentRaceIndex != undefined &&
            currentRaceIndex >= 0 && (
              <>
                <div className="race-row summary-columns">
                  <div className="race-titles ">
                    <span>{data.data[currentRaceIndex].raceName}</span>
                    <span>
                      {data.data[currentRaceIndex].raceLocation || "BetNFT"}
                    </span>
                  </div>
                  <div className="grid-item entry-fee">
                    {data.data.raceClass}
                  </div>

                  <div className="distance grid-item">
                    {data.data[currentRaceIndex].raceDistance}m
                  </div>
                  <div className="entry-fee grid-item">
                    {moment(data.data[currentRaceIndex].startTime).format(
                      "DD MMM, YYYY. hh.mm"
                    )}
                  </div>
                  <div className="prize-pool grid-item">$0 USDT</div>
                </div>
                <RaceSummary fromLiveRace={false}></RaceSummary>
              </>
            )}
          {!showSummary && (
            <>
              <div className="races">
                {Array.isArray(data?.data) ? (
                  data?.data.map((race: Race, index: number) => {
                    if (Date.now() > 1000 * 40 + race.startTime)
                      return (
                        <Link
                          to={`/race/${race.raceId}`}
                          className="race-row summary-columns"
                          key={race.raceId}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          <div
                            className="race-titles"
                            // onClick={() => handleDetail(index)}
                          >
                            {" "}
                            <span>
                              {race.raceName}
                              {race.cancelled && " - Abandoned"}
                            </span>
                            <span>{race.raceLocation || "BetNFT"}</span>
                          </div>
                          {/* <div className="race-titles"> {race.raceId}</div> */}
                          <div className="grid-item entry-fee">
                            {race.raceClass}
                          </div>

                          <div className="distance grid-item">
                            {race.raceDistance}m
                          </div>
                          <div className="entry-fee grid-item">
                            {moment(race.startTime).format(
                              "DD MMM, YYYY. hh:mm"
                            )}
                          </div>
                          <div className="prize-pool grid-item">$0 USDT</div>
                        </Link>
                      );
                  })
                ) : (
                  <div className="none">
                    {props.isLoading ? "Loading races..." : "No Races Found"}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
