import "./style.scss";
import * as react from "react";
import Button from "@mui/material/Button";
import Logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectAccount } from "../../store/mainSlice";
export function formatAddress(address: string) {
  return (
    address.substring(0, 6) + "..." + address.substring(address.length - 4)
  );
}

interface IProps {
  currentUsername?: string;

  handleConnect: (...args: any[]) => void;
}

export default function Navbar({ currentUsername, handleConnect }: IProps) {
  //const [address, setaddress] = react.useState("Connect Wallet");
  const [disabled, setDisabled] = react.useState(false);
  const [connected, setConnect] = react.useState(false);
  const { pathname } = useLocation();
  const account = useAppSelector(selectAccount);

  react.useEffect(() => {
    if (account) {
      setDisabled(true);
    }
  }, [account]);

  const disabledStyle: react.CSSProperties = { color: "white" };
  return (
    <>
      <div className="navbar">
        <div className="inner">
          <div className="link-group">
            <div
              className={`nav-link ${
                pathname.includes("/race") ? "light-green" : ""
              }`}
            >
              <Link to="/race">Racing</Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/breeding" ? "light-green" : ""
              }`}
            >
              <Link to="/breeding">Breeding</Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/marketplace" ? "light-green" : ""
              }`}
            >
              <Link to="/marketplace">Marketplace</Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/rental" ? "light-green" : ""
              }`}
            >
              <Link to="/rental">Rental</Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/kennel" ? "light-green" : ""
              }`}
            >
              <Link to="/kennel">Kennel</Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/getting_started" ? "light-green" : ""
              }`}
            >
              <a
                href="https://betnft.gitbook.io/welcome-to-gitbook/betnft/getting-started"
                target="_blank"
              >
                Getting Started
              </a>
            </div>
            <div
              className={`nav-link ${
                pathname === "/metaverse" ? "light-green" : ""
              }`}
            >
              <a
                href="https://wonderlandmeta.world/betameta/racetrack/index0.html"
                target="_blank"
              >
                Metaverse
              </a>
            </div>
            <div
              className={`nav-link ${
                pathname === "/account" ? "light-green" : ""
              }`}
            >
              <Link
                to="/account"
                className={pathname === "/account" ? "light-green" : ""}
              >
                {currentUsername || "Sign Up/In"}
              </Link>
            </div>
            <Button
              disabled={disabled}
              variant="contained"
              onClick={handleConnect}
              style={disabled ? disabledStyle : undefined}
              className="connect-btn"
            >
              {account ? formatAddress(account) : "Connect Wallet"}
            </Button>
            <Link to="/" className="logo">
              <img src={Logo} alt="logo"></img>
            </Link>
          </div>
        </div>
      </div>

      {/* <Button variant="contained" onClick={() => toggleContent("account")}>
        {currentUsername || "Sign Up/In"}
      </Button>
      <Button
        disabled={!!signature}
        variant="contained"
        //sx={{ marginLeft: "auto" }}
        onClick={handleSign}
        style={disabled ? disabledStyle : undefined}
      >
        {!!signature ? "Signed succesfully" : "Sign"}
      </Button> */}
    </>
  );
}
