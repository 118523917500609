import "./style.scss";
import Arrow from "../../assets/arrow.svg";
import * as react from "react";
import { useHomeContent } from "./../../../services/queries";

interface IProps {}

export default function Home(props: IProps) {
  const homeContent = useHomeContent();
  console.log(homeContent.data);
  return (
    <>
      <div className="homebg">
        {homeContent.data?.data && homeContent.data.data.homepage.content ? (
          <>
            <a
              className="box-flex"
              style={{
                top: "320px",
                left: "125px",
                transformOrigin: "0 0",
                transform: "rotate(340deg)",
              }}
              // href="https://mint.betnft.run/"
              // target="_blank"
            >
              {homeContent.data.data.homepage.content || ""}
            </a>
          </>
        ) : (
          <></>
        )}
        <div className="welcome">
          <span className="dark-green">Welcome</span> to the{" "}
          <span className="dark-green">world</span> of{" "}
          <span className="dark-green">NFT</span> racing!{" "}
          <img src={Arrow} className="arrow"></img>
        </div>

        <a
          className="box"
          style={{ top: "190px" }}
          href="https://mint.betnft.run/"
          target="_blank"
        >
          Buy a &nbsp;<span className="dark-green">Greyhound</span>
        </a>
        <a className="box div-box" style={{ top: "290px" }} href="/race">
          {" "}
          Start &nbsp;<span className="dark-green">Racing!</span>
        </a>
      </div>
    </>
  );
}
