import * as react from "react";
import "./joinpopup.scss";
import { Race, TokenInfo } from "../../../types";
import { getTokenImage } from "../../../services/queries";
import { UseMutationResult } from "@tanstack/react-query";
import LogoWShadow from "../../assets/logowshadow.png";
import { trimBloodlineAttribute } from "../kennel";
import { useAppSelector } from "../../../app/hooks";
import { Link } from "react-router-dom";
import {
  selectLottoMint1Tokens,
  selectG1Tokens,
  selectG2Tokens,
  selectG3Tokens,
  selectG4Tokens,
} from "./../../../store/mainSlice";
interface IProps {
  handleJoin: (tokenFamily: string, tokenId: number) => void;
  handleClose: () => void;
  raceData: Race;
  joinStatus: UseMutationResult;
  children?: react.ReactNode;
  error?: string;
}

export default function NameToken(props: IProps) {
  const [inputValue, setInputValue] = react.useState("");
  const LottoMint1Tokens = useAppSelector(selectLottoMint1Tokens);
  const G1Tokens = useAppSelector(selectG1Tokens);
  const G2Tokens = useAppSelector(selectG2Tokens);
  const G3Tokens = useAppSelector(selectG3Tokens);
  const G4Tokens = useAppSelector(selectG4Tokens);
  const joinStatus = props.joinStatus;
  //TODO: filter by allowed tokens such as the tokenFamilies allowed or race classes etc

  const [allowedTokens, setAllowedTokens] = react.useState<TokenInfo[]>([]);

  react.useEffect(() => {
    let tokens: TokenInfo[] = [];
    LottoMint1Tokens.map((token) => {
      if (
        props.raceData.entrants.findIndex(
          (t) =>
            t.tokenId === token.tokenId && t.tokenFamily === token.tokenFamily
        ) === -1
      )
        tokens.push(token);
    });
    G1Tokens.map((token) => {
      if (
        props.raceData.entrants.findIndex(
          (t) =>
            t.tokenId === token.tokenId && t.tokenFamily === token.tokenFamily
        ) === -1
      )
        tokens.push(token);
    });
    G2Tokens.map((token) => {
      if (
        props.raceData.entrants.findIndex(
          (t) =>
            t.tokenId === token.tokenId && t.tokenFamily === token.tokenFamily
        ) === -1
      )
        tokens.push(token);
    });
    G3Tokens.map((token) => {
      if (
        props.raceData.entrants.findIndex(
          (t) =>
            t.tokenId === token.tokenId && t.tokenFamily === token.tokenFamily
        ) === -1
      )
        tokens.push(token);
    });
    G4Tokens.map((token) => {
      if (
        props.raceData.entrants.findIndex(
          (t) =>
            t.tokenId === token.tokenId && t.tokenFamily === token.tokenFamily
        ) === -1
      )
        tokens.push(token);
    });
    setAllowedTokens(tokens);
  }, [props.raceData, props.joinStatus]);

  return (
    <>
      <div className="bg">
        <div className="close" onClick={() => props.handleClose()}>
          X
        </div>
        <div className="container">
          <div className="title">Join a race</div>
          <div className="subtitle">
            {props.raceData.raceName} at{" "}
            {props.raceData.raceLocation || "BetNFT"}
          </div>
          <div className="tokens">
            {allowedTokens.map((token, index) => {
              let bloodlineAttr = token.metadata?.attributes.findIndex(
                (attr) => attr.trait_type === "Bloodline"
              );
              let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
                token.metadata!.attributes[bloodlineAttr!].value
              );

              if (
                props.raceData.entrants.findIndex(
                  (t) =>
                    t.tokenId === token.tokenId &&
                    t.tokenFamily === token.tokenFamily
                ) === -1
              )
                return (
                  <div
                    className="row-popup"
                    key={token.tokenFamily + token.tokenId}
                  >
                    <div className="dog">
                      <div className="img-container">
                        <img
                          src={getTokenImage(token.metadata?.image!)}
                          className="join-dog"
                        ></img>
                      </div>

                      {/* <img src={DividerTest} className="divider"></img> */}
                      <div className="name-type">
                        <div className="name">{token.metadata?.name}</div>
                        <div className="type">
                          {bloodlineTag + " - " + bloodlineName}
                        </div>
                      </div>
                      <button
                        disabled={joinStatus.isLoading}
                        className="join-button"
                        onClick={() => {
                          props.handleJoin(token.tokenFamily, token.tokenId);
                        }}
                      >
                        Join
                      </button>
                    </div>
                  </div>
                );
            })}
          </div>
          {allowedTokens.length < 1 && (
            <div className="no-tokens-text">
              You don't have any dogs for this race. Please visit your
              <Link to="/kennel">kennel</Link>or
              <Link to="/" target="_blank">
                buy a valid
              </Link>
              dog.
            </div>
          )}
          <div className="error">{props.error} </div>
        </div>
        <img src={LogoWShadow} alt="logo" className="logo" />
      </div>
    </>
  );
}
